import React, { useState } from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.less'

function App(props: any) {
  const { title, list, image, imageStyle, rightStyle } = props

  return (
    <div className={styles.IntroduceOne}>
      <div className={styles.display}></div>
      <h5>{title}</h5>
      <div className={styles.wrap}>
        <div className={styles.right} style={rightStyle}>
          <Row gutter={[40, 30]}>
            {
              list.map((el: any, index: number) => <Col span={24}>
                <div key={index} className={`${styles.item}`}>
                  <div className={`flex-cc ${styles.index}`}>{index + 1}</div>
                  <p className={styles.title}>{el.label}</p>
                  <p className={styles.intro}>{el.intro}</p>
                </div>
              </Col>)
            }
          </Row>
          {/*<div className={`flex-cc ${styles.btn}`}>立即咨询 <img src="/image/ArrowRight.png" alt=""/></div>*/}
        </div>
        {/*style={{background: `url(${image}) 100% no-repeat`, ...imageStyle}}*/}
        <div className={styles.left} style={imageStyle}>
          <img src={image} alt=""/>
        </div>
      </div>
    </div>
  );
}

export default App;
