import React, {useEffect} from 'react';
import Header from '@/components/CommonHeader/index'
import CommonIntroduceFour from "@/components/CommonIntroduceFour/index"
import IntroduceTwo from '@/components/CommonIntroduceTwo/index'
import IntroduceThree from '@/components/CommonIntroduceThree/index'
import CommonFooterList from '../../../../components/CommonFooterList/index'
import styles from './index.module.less'

function App(props: any) {
    useEffect(() => {
      console.log('query', props)
    }, []);

    return (
      <div className={styles.home}>
        <div className={styles.header}>
          <Header
            title='融经理助手'
            subTitle={'移动端金融服务工具'}
            bgImage={'/image/manageBg.png'}
            bgMain={'/image/manageAssistantMain.png'}
            titleColor={'#556987'}
            subTitleColor={'#556987'}
            style={{
              height: '560px',
            }}
          ></Header>
        </div>
        <div className={`wrapItem`}>
          <CommonIntroduceFour
            subTitle='Convenient, fast, and easy-to-use financial service tools'
            title='方便、快捷、易用的金融服务工具'
            btnType={'btn'}
            text='融经理助手app是一款专注于为用户提供各种金融服务和工具的移动应用程序。并提供多种功能，包括但不限于金融数据查询、划扣服务、电子合同服务、智能风险监控等。融经理助手具备安全性高、数据期权、操作简便等特点，为用户提供了更好的用户体验和服务质量。无论是个人用户还是企业用户，融经理助手都能满足他们的金融需求，帮助他们更好地管理客户、提高效率。'
            image={'/image/manageAssistantT1Bg.png'}
          ></CommonIntroduceFour>
        </div>
        <div className={`wrapItem`}>
          <IntroduceTwo
            title='行业现状'
            subTitle='Industry status'
            list={[
              {
                icon: '/image/IndustrystatusItemT1.svg',
                intro: '在传统金融交易中，买家和卖家需要准确了解房产的历史交易记录、评估价值等信息。'
              },
              {
                icon: '/image/IndustrystatusItemT2.svg',
                intro: '房产数据查询和客户信用情况查询通常需要人工处理，操作繁琐。增加了工作量和处理时间。'
              },
              {
                icon: '/image/IndustrystatusItemT3.svg',
                intro: '缺乏金融工具类app，会使金融行业在交易和评估过程中面临更大的风险。'
              }
            ]}
          ></IntroduceTwo>
        </div>
        <div className={`wrapItem`}>
          <IntroduceThree
            title='应用优势 ｜ Application advantages'
            list={[
              { label: '提高工作效率', intro: '集成多个工作任务和流程，可以极大地提高工作效率。用户可以轻松管理和处理办公文档、金融业务、数据搜集等各种工作任务。' },
              { label: '提高服务质量', intro: '金融机构可以根据客户的需求和风险偏好，提供更加准确的投资建议和风险评估，提高客户的服务体验和满意度。' },
              { label: '精细化风险管理', intro: '金融机构可以实现精细化风险管理，根据不同的房产数据和客户信用情况，设置不同的交易策略和风险控制措施，提高风险管理的能力和准确性。' }
            ]}
            image='/image/manageAssistantT2.png'
            imageStyle={{
              position: 'absolute',
              width: '798px',
              right: '-162px',
              bottom: '90px'
            }}
          ></IntroduceThree>
        </div>
        <div className={`wrapItem`}>
          <CommonFooterList {...props}></CommonFooterList>
        </div>
      </div>
    );
}

export default App;
