import React, {useEffect, useRef} from 'react';
import styles from './index.module.less'
import CustomerServiceQR from "@/components/CustomerServiceQR";

function App(props: any) {
  const CustomerServiceQRRef = useRef<any>(null)

    return (
      <div className={styles.header} style={{background: 'url(/image/searchMainBg.png) 100% no-repeat'}}>
        <CustomerServiceQR ref={CustomerServiceQRRef}></CustomerServiceQR>
        <div className={`header flex-c ${styles.headerBox}`} style={{background: 'url(/image/searchMain.png) 100% no-repeat'}}>
          <div className={`flex-bc ${styles.headerBoxCp}`}>
            <div className={`${styles.box}`}>
              <h4>一键查询系统</h4>
              <h5>快速获取金融、地产等数据</h5>
              <div className={`flex-c ${styles.btnBox}`}>
                <div className={`flex-cc ${styles.btn}`} onClick={() => CustomerServiceQRRef.current.open()}>立即咨询</div>
              </div>
            </div>
            {/*<img src="/image/searchHeaderBgRight.png" alt=""/>*/}
          </div>
        </div>
        <div className={styles.data} style={{background: 'url(/image/searchDataBg.png)'}}>
          <ul className={`flex-cc`}>
            <li className={`flex-cc`}>
              <div className={styles.nub}>95%</div>
              <div className={styles.txt}>数据准确度</div>
            </li>
            <li className={`flex-cc`}>
              <div className={styles.nub}>70%</div>
              <div className={styles.txt}>提升审批效率</div>
            </li>
            <li className={`flex-cc`}>
              <div className={styles.nub}>99%</div>
              <div className={styles.txt}>数据完整度</div>
            </li>
          </ul>
        </div>
      </div>
    );
}

export default App;
