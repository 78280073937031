import React, {useRef, useState} from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.less'
import CommonApply from "@/components/CommonApply";
import CustomerServiceQR from "@/components/CustomerServiceQR";

function App(props: any) {
  const { title, subTitle, list, image, tip } = props
  const CommonApplyRef = useRef<any>(null)
  const CustomerServiceQRRef = useRef<any>(null)

  return (
    <div className={styles.IntroduceOne}>
      <CommonApply ref={CommonApplyRef}></CommonApply>
      <CustomerServiceQR ref={CustomerServiceQRRef}></CustomerServiceQR>
      <div className={styles.leftBox}>
        <div className={styles.display}></div>
        <h5>{title}</h5>
        <h6>{subTitle}</h6>
        <div className={styles.wrap}>
          <div className={styles.right}>
            <Row gutter={[40, 30]}>
              {
                list.map((el: any, index: number) => <Col span={24}>
                  <div key={index} className={`${styles.item}`}>
                    <div className={`flex-c ${styles.titleBox}`}>
                      <img src="/image/OneItemIcon.png" alt=""/>
                      <p className={styles.title}>{el.label}</p>
                    </div>
                    <p className={styles.intro}>{el.intro}</p>
                  </div>
                </Col>)
              }
            </Row>
          </div>
        </div>
        <div className={`${styles.btns} flex-c`}>
          <div className={`${styles.btn} ${styles.pr} flex-cc`} onClick={() => CommonApplyRef.current.open()}>
            <span>免费体验</span>
            <img src="/image/ArrowRight.png" alt=""/>
          </div>
          <div className={`${styles.btn} flex-cc`} onClick={() => CustomerServiceQRRef.current.open()}>
            <span>立即咨询</span>
            <img src="/image/IntroduceThreeMork.png" alt=""/>
          </div>
        </div>
        <p className={styles.brt}>{tip}</p>
      </div>
      <div className={styles.left}>
        <img src={image} alt=""/>
        {/*<div style={{background: `url(${image}) 100% no-repeat`, ...imageStyle}}></div>*/}
      </div>
    </div>
  );
}

export default App;
