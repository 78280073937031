export const leftList = [
  {
    icon: '/image/ProductServicest1.png',
    title: '数字金服智能推荐',
    intro: '综合分析财务指标、关联风险、发票进销项风险等信息，通过线上平台直接生成融资服务分析。'
  },
  {
    icon: '/image/ProductServicest2.png',
    title: '立即体验',
    intro: '我们提供的业务咨询，解决方案到落地实施交付全流程的专业服务，助力企业实现数字化',
    onClick: (CommonApplyRef: any) => {
      CommonApplyRef.open()
    }
  }
]

export const rightList = [
  {
    title: 'PC端系统',
    icon: '/image/headerProductServicesT1.png',
    list: [
      {
        title: '一键查询系统',
        intro: '快速获取金融、地产等数据',
        href: '/search'
      },
      {
        title: '金服助贷系统',
        intro: '为中小微企业提供融资支持的金融平台',
        href: '/LoanAssistance'
      },
      {
        title: '金服资方系统',
        intro: '管理和监控借贷资金的平台',
        href: '/thoseRepresentingCapital'
      },
      {
        title: '金服担保系统',
        intro: '为借款人提供担保服务和贷款准入率的平台',
        href: '/security'
      },
    ]
  },
  {
    title: '移动端应用',
    icon: '/image/headerProductServicesT2.png',
    list: [
      {
        title: '融经理',
        intro: '给客户提供多种授权通道',
        href: '/manage'
      },
      {
        title: '融经理助手',
        intro: '移动端数据查询服务',
        href: '/manageAssistant'
      },
      {
        title: '融代理',
        intro: '代理营销和用户管理',
        href: '/manageAgent'
      }
    ]
  },
  {
    title: '金服数据API',
    icon: '/image/headerProductServicesT3.png',
    list: [
      {
        title: '房产评估',
        intro: '专业评估与价值分析',
        onClick: (props: any) => {
          props.history.push(`/manageApi?index=0&time=${new Date().getTime()}1`)
        }
      },
      {
        title: '历史成交',
        intro: '了解市场的历史走势和价值变化',
        onClick: (props: any) => {
          props.history.push(`/manageApi?index=1&time=${new Date().getTime()}2`)
        }
      },
      {
        title: '信用报告',
        intro: '基于大数据技术与海量分析',
        onClick: (props: any) => {
          props.history.push(`/manageApi?index=1&time=${new Date().getTime()}3`)
        }
      },
      {
        title: '个人诉讼',
        intro: '个人涉诉信息查询',
        onClick: (props: any) => {
          props.history.push(`/manageApi?index=0&time=${new Date().getTime()}4`)
        }
      },
      {
        title: '企业诉讼',
        intro: '企业涉诉信息查询',
        onClick: (props: any) => {
          props.history.push(`/manageApi?index=0&time=${new Date().getTime()}5`)
        }
      }
    ]
  }
]
