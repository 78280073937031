/*
 * @Author: rogi
 * @Date: 2022-10-25 16:13:03
 * @LastEditTime: 2023-02-13 09:17:57
 * @LastEditors: rogi
 * @Description: 描述
 */
const Config = {
	// 环境
	// env: 'test',
	env: process.env.REACT_APP_ENV,
	// 所有环境域名
	hostUrl: {
		// dev: 'http://192.168.1.105:9202', // 珍
		dev: 'http://192.168.1.105:50014', // 测试
		// dev: 'http://106.55.169.106:9204', // 测试
		test: 'http://106.55.169.106:9204', // 测试
		test1: 'http://106.55.169.106:9204', // 测试
		// dev: 'http://www.szjf.k8s:9203', // 测试
		// dev: 'http://192.168.1.105:9203', // 测试
		// test: 'http://192.168.1.34:9202', // 测试
		prod1: 'https://zhudaisaas.com:8001',
		prod2: 'http://www.szjf.k8s:8001',
		mock: ''
	},
	// API直接使用url
	apiSign: true,
	// 全局header
	header: {
		'content-Type': 'application/x-www-form-urlencoded',
		"Access-Control-Allow-Headers": "*"
	},

	token: 'RogiToken',

	// 全局请求参数
	data: () => {
		return {}
	},
	// 成功返回码
	successCode: [200, 1, 0],
	// 忽略提示返回码
	ignoreToastCode: [-1, 500]
};

export default Config
