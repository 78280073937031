import React, { useRef, useState, useEffect } from "react";
import { Form, Input, Button, Space, Tabs, Checkbox  } from "antd";
import styles from './index.module.less'
import type { TabsProps } from 'antd';

const RegistrationForm: React.FC = (props: any) => {
    useEffect(() => {
        console.log('query', props)
    }, ['']);


    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    type FieldType = {
        phone?: string;
        code?: string;
        password?: string;
    };
    const tabValue = useRef('1');
    const onChange = (key: string) => {
        tabValue.current = key;
        console.log(tabValue.current);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: '账号登录',
            children: '',
        },
        {
            key: '2',
            label: '短信登录',
            children: '',
        }
    ];

    const [countdown, setCountdown] = useState(60);
    const [isCounting, setIsCounting] = useState(false);
    const getCode = () => {
        if (!isCounting) {
            setIsCounting(true);
            // 模拟发送验证码的异步操作
            setTimeout(() => {
                setIsCounting(false);
                setCountdown(60);
            }, 60000);
        }
    }
    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isCounting) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [isCounting]);


    return (
        <div className={styles.sign} id="sign">
            <div className={styles.title}>欢迎使用</div>
            <div className={styles.tabs}>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
            <div className={styles.form}>
                <Form
                    name="basic"
                    style={{ width: 380 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        label=""
                        name="phone"
                        rules={[{ required: true, message: '请输入手机号码' }]}
                    >
                        <Input placeholder="请输入手机号码" />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label=""
                        name="password"
                        rules={[{ required: true, message: '请输入密码!' }]}
                        style={{ display: (tabValue.current == '1' ? 'block' : 'none') }}
                    >
                        <Input placeholder="请输入密码" />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label=""
                        name="code"
                        rules={[{ required: true, message: '请输入验证码' }]}
                    >
                        <Space.Compact>
                            <Input placeholder="请输入验证码" className={styles.input} maxLength={6} />
                            <Button type="text" style={{ position: 'absolute', right: 2, color: '#3966FF', top: 8, zIndex: 999 }} onClick={getCode} disabled={isCounting}>{isCounting ? `${countdown}秒后重新获取` : "获取验证码"}</Button>
                        </Space.Compact>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>


            <div className={styles.bottom}>
                <div>忘记密码？</div>
                <span>新账号注册</span>
            </div>
        </div>
    );
};


export default RegistrationForm;
