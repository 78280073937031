import React, {useEffect} from 'react';
import Header from './components/Header/index'
import IntroduceOne from './components/IntroduceOne/index'
import IntroduceTwo from './components/IntroduceTwo/index'
import IntroduceThree from './components/IntroduceThree/index'
import CommonFooterList from '../../../../components/CommonFooterList/index'
import styles from './index.module.less'

function App(props: any) {
    useEffect(() => {
      console.log('query', props)
    }, ['']);

    return (
      <div className={styles.home}>
        <Header></Header>
        <div className={`wrapItem`}>
          <IntroduceOne></IntroduceOne>
        </div>
        <div className={`wrapItem`}>
          <IntroduceTwo {...props}></IntroduceTwo>
        </div>
        <div className={`wrapItem`}>
          <CommonFooterList {...props}></CommonFooterList>
        </div>
      </div>
    );
}

export default App;
