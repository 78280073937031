import React, { useState } from 'react';
import OneItem from '../OneItem/index'
import OneItemTwo from '../OneItemTwo/index'
import CommonReportComposition from '../CommonReportComposition/index'
import styles from './index.module.less'

function App(props: any) {
  const { title, list, image, imageStyle } = props

  return (
    <div className={styles.IntroduceOne}>
      <OneItem
        title='个人信用及风险大数据'
        subTitle='对个人进行信用评估，识别风险人群，降低逾期可能'
        list={[
          { label: '有效识别风险人群，降低逾期可能性', intro: '我们通过对个人进行信用评估，有效识别风险人群，降低逾期可能性，帮助金融机构更好地管理风险，为客户提供更可靠的金融服务' },
          { label: '丰富的数据源和智能算法', intro: '我们的信用评估技术基于丰富的数据源和智能算法，能够准确评估个人信用状况，减少不良风险，为客户提供更加优质的金融服务' }
        ]}
        tip={"减少不良风险 / 丰富的数据源 /  降低逾期可能性"}
        image='/image/TabT2.png'
      ></OneItem>
      <OneItemTwo
        title='方案亮点'
        subTitle='Highlights of the plan'
        list={[
          {
            icon: '/image/TabT22ItemT1.svg',
            title: '准确性高',
            text: '我们的信用评估技术具有高准确性和多维度特点，通过综合分析丰富的数据源和智能算法，能够更准确地评估个人信用状况，降低逾期风险，提高金融机构的信贷风险管理水平。'
          },
          {
            icon: '/image/TabT22ItemT2.svg',
            title: '针对性强',
            text: '我们基于行业场景提供定制化的数据分析服务，根据客户的业务需求和数据特点，量身定制符合行业特点的分析模型和算法，帮助客户理解和利用数据，提高决策的准确性和有效性。'
          },
          {
            icon: '/image/TabT22ItemT3.svg',
            title: '有效性高',
            text: '我们采用机器学习、神经网络等多种先进技术，建立科学的数据建模和分析模型，为客户提供全面、准确的信用评估服务。'
          }
        ]}
      ></OneItemTwo>
      <CommonReportComposition
        list={[
          { icon: '/image/CommonReportCompositionT1.svg', color: '#0B63E5', label: '评分卡', intro: '基于评分卡评估规则，对个人的综合未读进行打分评估，给出评估结果及建议' },
          { icon: '/image/CommonReportCompositionT2.svg', color: '#0F9918', label: '定制化部署', intro: '可用于定制化部署风控策略，快速上线' },
          { icon: '/image/CommonReportCompositionT3.svg', color: '#FF8800', label: '结合实际业务调配策略', intro: '可提供底层全量特征进行筛选，结合实际业务需求，调配策略' },
          { icon: '/image/CommonReportCompositionT4.svg', color: '#E54545', label: '自定义风控策略', intro: '可使用标准化策略，或自定义风控策略' }
        ]}
      ></CommonReportComposition>
    </div>
  );
}

export default App;
