/*
 * @Author: rogi
 * @Date: 2022-10-25 16:13:03
 * @LastEditTime: 2022-12-07 14:08:51
 * @LastEditors: rogi
 * @Description: 描述
 */
import axios from 'axios'
import config from '../config'
import headerCode from './headerFindeCode'
import {
  getApi,
  CheckSuccessCode,
  getHots
} from './lib'
function request(url, data = {}, Options = {}) {
  return new Promise((resolve, reject) => {
    let {
      // 请求方式
      method,
      // 成功返回的code
      successCode = config.successCode,
      // 是否强制返回then
      forceReturn,
      apiClass = false,
      headers = {},
      showToast = true
    } = Options;
    const apiUrl = !apiClass && getApi(url);
    const requestData = {}
    if (method === 'get' || method === 'delete') {
      requestData.params = data
    } else {
      requestData.data = data
    }
    return axios({
      url: apiClass ? getHots() + url : apiUrl,
      headers: {
        "Authorization": localStorage.getItem('access_token') || "",
        "enterprise_id": localStorage.getItem('enterprise_id') || "",
        "expires_in": localStorage.getItem('expires_in') || "",
        "user_id": localStorage.getItem('user_id') || "",
        "user_type": localStorage.getItem('user_type') || "",
        ...headers
      },
      ...requestData,
      method
    }).then(res => {
      if (CheckSuccessCode(res, method, successCode, forceReturn, showToast)) {
        resolve(res.data.data)
      } else {
        reject(res.data)
        if (res.data.code === 406) {
          localStorage.removeItem('access_token')
          localStorage.removeItem('enterprise_id')
          localStorage.removeItem('expires_in')
          localStorage.removeItem('user_id')
          localStorage.removeItem('user_type')
          window.location.href = '#/login'
        }
      }
    }).catch((res) => {
      console.log('ewqewq', res)
      reject(res)
      const status = res.response.status;
      headerCode(status)
    })
  })
}
export function get(url, data = {}, Options = {}) {
  return request(url, data, {
    ...Options,
    method: 'get'
  })
}
export function post(url, data = {}, Options = {}) {
  return request(url, data, {
    ...Options,
    method: 'post'
  })
}
export function put(url, data = {}, Options = {}) {
  return request(url, data, {
    ...Options,
    method: 'put'
  })
}
export function del(url, data = {}, Options = {}) {
  return request(url, data, {
    ...Options,
    method: 'delete'
  })
}
