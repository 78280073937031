/*
 * @Author: rogi
 * @Date: 2022-10-25 16:13:03
 * @LastEditTime: 2022-12-05 13:35:57
 * @LastEditors: rogi
 * @Description: 描述
 */
import {apiObj} from '../../views/index'
import config from '../config'
import { Button, message, Space } from 'antd';
export const getHots = () => {
    const env = config['env'];
    const host = config['hostUrl'][env];
    return host
}

export const getApi = (apiModules) => {
    const [apiName, apiKey] = apiModules.split('.');
    try{
        if(!apiName || !apiKey) {
            // throw '检查api是否错了'
        }
    }catch(e){
        return  {}
    }
    const apiUrl = apiObj[apiName]['ActionList'][apiKey]['url'];
    return getHots() + apiUrl
};
// 检查是否在正确返回码内
export const CheckSuccessCode = (res, method, ignoreToastCode = [], forceReturn, showToast) => {
    const status = res.status
    if(status === 200) {
        const code = res.data.code
        const isCode = ignoreToastCode.findIndex(item => item === code);
        if((isCode >= 0 || forceReturn)) {
            if (showToast) {
                if (method !== 'get') {
                    message.success(res.data.message);
                }
            } else if (method === 'post' && showToast) {
                message.success(res.data.message);
            }
            return true
        } else if (showToast) {
            message.error(res.data.message);
            return false
        }
    } else {
        message.error('服务器异常');
        // alert('');
        return false
    }
}
