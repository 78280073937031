/*
 * @Author: rogi
 * @Date: 2022-10-25 16:13:03
 * @LastEditTime: 2022-10-25 16:13:40
 * @LastEditors: rogi
 * @Description: 描述
 */
import cookie from 'react-cookies'
export default (status) => {
  const headerCode = {
    '401': () => {
      // cookie.remove('RogiToken')
      // alert('请登录')
    },
    '402': () => {
    },
    '403': () => {
    }
  }
  headerCode[String()] && headerCode[String()]()
}