export default [
  { path: "/", component: '/home', name: "首页", menu: true },
  { path: "/search", component: '/search', name: "一键查询", menu: true },
  { path: "/manageAssistant", component: '/manageAssistant', name: "融经理助手", menu: true },
  { path: "/manageAgent", component: '/manageAgent', name: "融代理", menu: true },
  { path: "/manage", component: '/manage', name: "融经理", menu: true },
  { path: "/manageApi", component: '/manageApi', name: "融经理API", menu: true },
  { path: "/riskControl", component: '/riskControl', name: "智能风控", menu: true },
  { path: "/cooperationChannels", component: '/cooperationChannels', name: "合作渠道", menu: true },
  { path: "/aboutUs", component: '/aboutUs', name: "关于我们", menu: true },
  { path: "/LoanAssistance", component: '/LoanAssistance', name: "金服助贷", menu: true },
  { path: "/thoseRepresentingCapital", component: '/thoseRepresentingCapital', name: "金服资方", menu: true },
  { path: "/security", component: '/security', name: "金服担保", menu: true },

];
