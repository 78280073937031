import React, {useRef, useState} from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.less'
import CustomerServiceQR from "@/components/CustomerServiceQR";

function App(props: any) {
  const { subTitle, title, text, image, btnType, qrText } = props
  const CustomerServiceQRRef = useRef<any>(null)

  return (
    <div className={`flex-bc ${styles.IntroduceOne}`}>
      <CustomerServiceQR ref={CustomerServiceQRRef}></CustomerServiceQR>
      <div>
        <div className={styles.display}></div>
        <h6>{subTitle}</h6>
        <h5>{title}</h5>
        <div className={styles.wrap}>
          <div className={styles.right}>
            <div className={styles.text}>{text}</div>
            {
              btnType === 'btn' ?
                <div className={`flex-cc ${styles.btn}`} onClick={() => CustomerServiceQRRef.current.open()}><span>立即咨询</span><img src="/image/ArrowRight.png" alt=""/></div> :
                <>
                  <div className={`${styles.btn} ${styles.qrBtn}`}>
                    <img src="" alt=""/>
                    <span>{qrText || '扫码下载'}</span>
                  </div>
                </>
            }

          </div>
        </div>
      </div>
      <div className={styles.left}>
        <img src={image} alt=""/>
      </div>
    </div>
  );
}

export default App;
