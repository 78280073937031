import React, { useState } from 'react';
import styles from './index.module.less'

function App(props: any) {
  const { title, subTitle, list, two } = props

  return (
    <div className={`flex-c ${styles.OneItemTwo}`}>
      <div className={styles.bg} style={{background: `url(/image/TabT2Bg.png) 100% no-repeat`}}>
        <img src="/image/TabT2BgLeftTop.png" alt="" className={styles.TabT2BgLeftTop}/>
        <img src="/image/TabT2BgBotrtomRight.png" alt="" className={styles.TabT2BgBotrtomRight}/>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subTitle}>{subTitle}</div>
        <ul className={two ? styles.two : ''}>
          {
            list.map((el: any, index: number) => <li key={index}>
              <img src={el.icon} alt=""/>
              <div className={styles.itemConte}>
                <div className={styles.contentTitle}>{el.title}</div>
                <div className={styles.contentText}>{el.text}</div>
              </div>
            </li>)
          }
        </ul>
      </div>
    </div>
  );
}

export default App;
