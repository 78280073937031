import React from 'react';
import styles from './index.module.less'
import { Col, Row } from 'antd';

function App(props: any) {
  const list = [
    { label: '高品质产品', icon: '/image/ReasonT1.svg', intro: '依托金服的产品能力，助力更优质的客户服务' },
    { label: '培训支持', icon: '/image/ReasonT2.svg', intro: '线上直播培训、视频教学、线下渠道经理集训等众多形式。' },
    { label: '业务支持', icon: '/image/ReasonT3.svg', intro: '提供金融业务，助您轻松获得资金支持，满足各种贷款需求。' },
    { label: '技术支持', icon: '/image/ReasonT4.svg', intro: '我们拥有丰富的技术经验和稳定的系统，随时为您提供全方位的技术支持，确保您的业务顺利运营。' },
    { label: '市场产品支持', icon: '/image/ReasonT5.svg', intro: '为您提供多样的贷款方案，满足不同需求，快速审批，灵活还款，助您解决资金难题。' },
    { label: '服务质量有保证', icon: '/image/ReasonT6.svg', intro: '数字金服培训认证，确保合作伙伴服务质量，为企业提供可靠保障。' }
  ]

  return (
    <div className={styles.Reason}>
      <img src="/image/ReasonLeftBgIcon.png" alt="" className={styles.ReasonLeftBgIcon}/>
      <div className={styles.content}>
        <Row gutter={[24, 28]}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={styles.oneItem}>
              <div className={styles.display}></div>
              <h5>选择数字金服的核心理由</h5>
              <h6>The core reasons for choosing digital financial services</h6>
            </div>
          </Col>
          {
            list.map(el => <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className={styles.item}>
                <div className={`flex-cc ${styles.heade}`}>
                  <img src={el.icon} alt=""/>
                </div>
                <div className={styles.title}>{el.label}</div>
                <div className={styles.intro}>{el.intro}</div>
              </div>
            </Col>)
          }
        </Row>
      </div>
    </div>
  );
}

export default App;
