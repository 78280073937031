import React, {useEffect, useState} from 'react';
import styles from './index.module.less'
import Header from "@/components/CommonHeader";
import { Tabs } from 'antd';
import TabT1 from './components/TabT1/index'
import TabT2 from './components/TabT2/index'
import TabT3 from './components/TabT3/index'
import TabT4 from './components/TabT4/index'
import TabT5 from './components/TabT5/index'

function App(props: any) {
  const [ tabsActiveKey, setTabsActiveKey ] = useState('1')

  useEffect(() => {
    if (props.query.index) {
      setTabsActiveKey(props.query.index)
    }
  }, [props.query.index])

  const items = [
    { key: '1', label: `数字金融智能风控` },
    { key: '2', label: `个人信用及风险大数据` },
    { key: '3', label: `信用及风险咨询` },
    { key: '4', label: `数字化融资匹配` },
    { key: '5', label: `企业税务风险检测` }
  ];

  const chilrenObj: any = {
    "1": TabT1,
    "2": TabT2,
    "3": TabT3,
    "4": TabT4,
    "5": TabT5
  }
  const Chilren: any = chilrenObj[tabsActiveKey]

  return (
    <div className={styles.riskControl}>
      <div className={styles.header}>
        <Header
          title='多维数字化分析赋能金融决策'
          subTitle={'智能生成分析结果，自动匹配产品服务，帮助企业快速选择最佳解决方案'}
          bgImage={'/image/riskControlBg.png'}
          style={{
            height: '492px'
          }}
          btn={false}
        ></Header>
      </div>
      <div className={styles.tabs}>
        <Tabs
          activeKey={tabsActiveKey}
          items={items}
          onChange={(e) => setTabsActiveKey(e)}
        />
      </div>
      <div className={styles.content}>
        <Chilren></Chilren>
        {/*<TabT1></TabT1>*/}
        {/*<TabT2></TabT2>*/}
      </div>
    </div>
  );
}

export default App;
