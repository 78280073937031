import React, {useEffect} from 'react';
import Header from '@/components/CommonHeader/index'
import CommonIntroduceFour from "@/components/CommonIntroduceFour/index"
import IntroduceTwo from '@/components/CommonIntroduceTwo/index'
import IntroduceThree from '@/components/CommonIntroduceThree/index'
import CommonFooterList from '../../../../components/CommonFooterList/index'
import styles from './index.module.less'

function App(props: any) {
    useEffect(() => {
      console.log('query', props)
    }, []);

    return (
      <div className={styles.home}>
        <div className={styles.header}>
          <Header
            title='融代理'
            subTitle={'代理营销和用户管理'}
            bgImage={'/image/manageBg.png'}
            bgMain={'/image/manageAgentMain.png'}
            titleColor={'#556987'}
            subTitleColor={'#556987'}
            style={{
              height: '560px',
            }}
          ></Header>
        </div>
        <div className={`wrapItem`}>
          <CommonIntroduceFour
            subTitle='Convenient, efficient, and secure data distribution tool'
            title='便捷、高效、安全的数据分发工具'
            btnType={'btn'}
            qrText='进入小程序'
            text={<div>
              <p>融代理是一种专门用于数据交易和分销的移动应用程序。它为数据代理者提供了一个便捷的平台，使他们能够高效地交换和分销数据应用。</p>
              <p>融代理提供了安全可靠的数据交易机制。它支持多种支付方式，确保数据购买者可以方便地完成支付。</p>
              <p>此外，融代理还提供了数据分析和报告的功能。数据代理者可以通过应用对购买的数据进行分析和挖掘，获取有价值的信息和洞察。他们可以生成报告和可视化图表，帮助决策者更好地理解和利用数据，提升业务效率和竞争力。</p>
            </div>}
            image={'/image/manageAgentT2.png'}
          ></CommonIntroduceFour>
        </div>
        <div className={`wrapItem`}>
          <IntroduceThree
            title='应用优势 ｜ Application advantages'
            list={[
              { label: '营收高效', intro: '数据代理商可以将平台数据资源快速变现，迅速找到潜在的数据购买者。数据分销的过程简单便捷，只需转发相应链接并完成充值，即可获得收益。' },
              { label: '提现方便', intro: '数据代理商可以通过简单的操作，如点击、选择等，即可完成提现。无需复杂的步骤和流程，让商家更加轻松地完成提现操作。' },
              { label: '持续性收益', intro: '通过长期稳定的商业模式，为代理商提供持续的收益机会。代理商可以通过持续合作，获得长期稳定的利润增长，无需担心市场波动对收益的影响。' }
            ]}
            image='/image/manageAgentT3.png'
            imageStyle={{
              position: 'absolute',
              width: '798px',
              height: '788px',
              right: '-22px',
              bottom: '-20px'
            }}
          ></IntroduceThree>
        </div>
        <div className={`wrapItem`}>
          <CommonFooterList {...props}></CommonFooterList>
        </div>
      </div>
    );
}

export default App;
