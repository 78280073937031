import React from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.less'

function App(props: any) {
  const { list } = props

  return (
    <div className={styles.CommonReportComposition}>
      <div className={styles.title}>报告构成</div>
      <div className={styles.subTitle}>评估建议+评级评分+特征指标</div>
      <div className={styles.wrap}>
        <Row gutter={[24, 24]}>
          {
            list.map((el: any) => <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className={styles.item} style={{borderColor: el.color}}>
                <img src={el.icon} alt=""/>
                <div className={styles.content}>
                  <div className={styles.title}>{el.label}</div>
                  <div className={styles.intro}>{el.intro}</div>
                </div>
              </div>
            </Col>)
          }
        </Row>
      </div>
    </div>
  );
}

export default App;
