import React, {useEffect} from 'react';
import styles from './index.module.less'
import Header from "@/components/CommonHeader";
import ItemOne from './components/ItemOne/index'
import ItemTwo from './components/ItemTwo/index'

function App(props: any) {

  useEffect(() => {
    if (props.query.rollId) {
      const rollEl = document.getElementById(props.query.rollId)
      if (rollEl) {
        setTimeout(() => {
          document.body.scrollTop = rollEl.offsetTop;
          document.documentElement.scrollTop = rollEl.offsetTop;
        }, 20)
      }
    }
  }, [])

  return (
    <div className={styles.aboutUs}>
      <Header
        title='关于我们'
        subTitle={'我们作为专业且独立的大数据技术服务提供商，致力于帮助金融业务实现数字化升级，以好地适应快速变化的市场环境'}
        bgImage={'/image/aboutUsHeaderBg.png'}
        style={{
          height: '492px'
        }}
        btn={false}
      ></Header>
      <ItemOne></ItemOne>
      <ItemTwo {...props}></ItemTwo>
    </div>
  );
}

export default App;
