import React from 'react';
import FrontendAuth from '../router/FrontendAuth'
import { routerObj } from '../views'
import Header from './components/Header/index'
import Apply from './components/Apply/index'
import Footer from './components/Footer/index'
import BroadsideModule from './components/BroadsideModule/index'

function RouterObj(props: any) {
  // console.log('props', props)
  if (props.location.pathname === '/login') {
    return <FrontendAuth {...props} routerConfig={routerObj} />
  } else {
    return (
      <div id="main">
        <Header {...props}></Header>
        <FrontendAuth {...props} routerConfig={routerObj} />
        <Apply></Apply>
        <Footer {...props}></Footer>
        <BroadsideModule {...props}></BroadsideModule>
      </div>
    );
  }
}

export default RouterObj;
