/*
 * @Author: rogi
 * @Date: 2022-08-23 16:28:52
 * @LastEditTime: 2023-01-10 09:24:00
 * @LastEditors: rogi
 * @Description: 描述
 */
/**
 * 正则校验库
*/

/**
 * 校验身份证号
 * @param {String} cardNo 身份证号
 * @return {boolean}
 */
export const IDregex = (cardNo) => {
	let res = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
	if(res.test(cardNo)) {
		return true
	}
	return false
}

/**
 * 校验手机号
 * @param {Number} phone 手机号
 * @return {boolean}
 */
export const phoneRegex = (phone) => {
	var res = /^[1][3,4,5,6.7,8,9][0-9]{9}$/
	if(res.test(phone)) {
		return true
	}
	return false
}

/**
 * 校验限制中文和符号输入
 * @param {Number} val 值
 * @return {boolean}
 */
 export const stringRegex = (val) => {
	var res = /^[0-9A-Za-z]+$/
	if(res.test(val)) {

		return true
	}
	return false
}

/**
 * 校验限制中文
 * @param {Number} val 值
 * @return {boolean}
 */
 export const chineseRegex = (val) => {
	var res = /[\u4E00-\u9FA5\uF900-\uFA2D]{1,}/;
	if(!res.test(val)) {
		return true
	}
	return false
}
export const passwordRegex = (val) => {
 	var res = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./])[a-zA-Z0-9~!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]{8,16}$/;
	if(res.test(val)) {
		return true
	}
	return false
}


/**
 * 校验限制数字且两位小数
 * @param {Number} val 值
 * @return {boolean}
 */
 export const numberRegex = (val) => {
	var res = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
	if(res.test(val)) {

		return true
	}
	return false
}




/**
 * 校验限制数字正数
 * @param {Number} val 值
 * @return {boolean}
 */
 export const positiveNumRegex = (val) => {
	if(val > 0) {

		return true
	}
	return false
}


/**
 * 校验限制数字正整数
 * @param {Number} val 值
 * @return {boolean}
 */
 export const integerRegex = (val) => {
	var res = /^[1-9]+[0-9]*$/
	if(res.test(val)) {

		return true
	}
	return false
}



/**
 * 校验邮箱
 * @param {String} phone 邮箱号
 * @return {boolean}
 */
export const emailRegex = (email) => {
	let res = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
	if (email && res.test(email)) {
		return true;
	}
	return false;
}


/**
 * 校验固话固话
 * @return {boolean}
 */
 export const mobRegex = (mob) => {
	let res = /^0\d{2,3}-?\d{7,8}$/;
	if (res.test(mob)) {
		return true;
	}
	return false;
}

/**
 * 校验固话+手机号
 * @return {boolean}
 */
export const mobPhone = (nub) => {
	return phoneRegex(nub) || mobRegex(nub)
}

/**
 * @description: 检验统一社会信用代码
 * @param {String} value
 * @return {*}
 */
export const usccode = (value) => {
	let res = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
	if (res.test(value)) {
		return true;
	}
	return false;
}

// form表单rule字段
// 验证邮箱
export const ruleEmail = ({ getFieldValue }) => ({
	validator(_, value) {
		if (emailRegex(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('请输入正确的电子邮编!'));
	}
})
// 验证邮箱
export const ruleEmailNull = ({ getFieldValue }) => ({
	validator(_, value) {
		if (!value) {
			return Promise.resolve();
		}
		if (emailRegex(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('请输入正确的电子邮编!'));
	}
})
//
// 手机号
// export const rulePhone = { validator: (_, _v, cb) => { phoneRegex(_v) ? cb() : cb('请输入正确的手机号') }, trigger: 'blur' }
export const rulePhone = ({ getFieldValue }) => ({
	validator(_, value) {
		if (phoneRegex(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('请输入正确的手机号!'));
	}
})
// 限制中文和符号
export const ruleString = ({ getFieldValue }) => ({
	validator(_, value) {
		if (stringRegex(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('不可输入中文或符号!'));
	}
})
// 限制中文和符号
export const chinese = ({ getFieldValue }) => ({
	validator(_, value) {
		if (chineseRegex(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('不可输入中文!'));
	}
})
// 限制中文
export const chinese2 = ({ getFieldValue }) => ({
	validator(_, value) {
		if (!chineseRegex(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('只能输入中文!'));
	}
})

export const password = () => ({
	validator(_, value) {
		if (passwordRegex(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('密码须为长度8~16位的英文、数字、特殊符号组合'));
	}
// /^[a-zA-Z0-9~!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]{8,16}$/
})
// 限制数字且小数是两位
export const ruleNumber = { validator: (_, _v, cb) => { numberRegex(_v) ? cb() : cb('仅可输入最多两位小数') }, trigger: 'blur' }
// 限制数字为正整数
export const ruleInteger = { validator: (_, _v, cb) => { integerRegex(_v) ? cb() : cb('仅可输入正整数') }, trigger: 'blur' }


// 限制数字为正数
export const rulePositive = { validator: (_, _v, cb) => { positiveNumRegex(_v) ? cb() : cb('仅可输入正数') }, trigger: 'change' }

//固话
export const ruleMob = { validator: (_, _v, cb) => { mobRegex(_v) ? cb() : cb('请输入正确固话') }, trigger: 'blur' }
export const ruleMobPhone = { validator: (_, _v, cb) => { mobPhone(_v) ? cb() : cb('请输入正确的固话或手机号') }, trigger: 'blur' }
// 身份证
export const ruleIDregex = ({ getFieldValue }) => ({
	validator(_, value) {
		if (IDregex(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('请输入正确的身份证号码!'));
	}
})
// 统一社会信用代码
// export const uscCoderegex = { validator: (_, _v, cb) => { IDregex(_v) ? cb() : cb('请输入正确的统一社会信用代码') }, trigger: 'blur' }
