import React, { useState } from 'react';
import OneItem from '../OneItem/index'
import OneItemTwo from '../OneItemTwo/index'
import styles from './index.module.less'

function App(props: any) {
  const { title, list, image, imageStyle } = props

  return (
    <div className={styles.IntroduceOne}>
      <OneItem
        title='数字金服智能风控'
        subTitle='Intelligent risk control for digital finance'
        list={[
          { label: '数据采集与智能分析', intro: '我们为中小企业提供信用评估数字化技术服务，凭借丰富的数据服务经验和多维度数据源，为金融机构提供数据采集与智能分析服务' },
          { label: '提高数据的可靠性、智能性和有效性', intro: '能够高效地解决数据来源可靠性、统计分析滞后性等问题，帮助金融机构做出更加准确的决策' }
        ]}
        tip={"金融科技 / 金融风控 / 数据采集 / 智能分析"}
        image='/image/TabT1T1.png'
      ></OneItem>
      <OneItemTwo
        title='方案亮点'
        subTitle='Highlights of the plan'
        two={true}
        list={[
          {
            icon: '/image/TabT2ItemT1.svg',
            title: '风控效率更高',
            text: '基于多维度数据，自动评估企业近三年的经营情况，一键生成企业分析报告，快速完成资质审查和辅助判断客户的风险程度，审查标准、评估维度均可由灵活定制。'
          },
          {
            icon: '/image/TabT2ItemT2.svg',
            title: '数据维度全',
            text: '可自动采集企业财务、税务、发票、司法等120多类、2800多项信用数据。借助于多维度数据、自我学习能力的风控模型、实时计算结果，快速、灵活地进行风控策略适配与调优。'
          },
          {
            icon: '/image/TabT2ItemT3.svg',
            title: '降低人工成本',
            text: '自动采集、分析多维度涉企信用数据，提高数据处理效率，改变传统人工信息收集方式，大幅度降低人工成本、运营成本。'
          },
          {
            icon: '/image/TabT2ItemT4.svg',
            title: '批量化操作',
            text: '通过对企业发票、税务、财务等数据进行多维度采集、清洗加工、智能分析和标准化处理，能有效支撑大样本、多维度的数据需求'
          }
        ]}
      ></OneItemTwo>
    </div>
  );
}

export default App;
