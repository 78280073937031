import React, { useState } from 'react';
import OneItem from '../OneItem/index'
import OneItemTwo from '../OneItemTwo/index'
import styles from './index.module.less'

function App(props: any) {
  const { title, list, image, imageStyle } = props

  return (
    <div className={styles.IntroduceOne}>
      <OneItem
        title='数字化融资匹配'
        subTitle='通过智能算法和数据分析，自动寻找最适合的融资方案'
        list={[
          { label: '人工智能技术', intro: '数字化融资匹配利用人工智能技术，帮助金融机构全方位多维度刻画用户，从而使金融机构能够更精准地寻求到对应用户。' },
          { label: '实时分析用户质量', intro: '帮助金融机构通过对来自第三方流量的特定标签，实时分析用户质量，确保用户符合特定的营销评分范围，排除评分较低的欺诈用户。' }
        ]}
        tip={"人工智能 / 精准获客 /  多维度刻画用户"}
        image='/image/TabT3.png'
      ></OneItem>
      <OneItemTwo
        title='方案亮点'
        subTitle='Highlights of the plan'
        two={true}
        list={[
          {
            icon: '/image/TabT6ItemT1.svg',
            title: '高效准确',
            text: '智能匹配系统能够通过强大的算法和深度学习技术，快速而准确地分析大量的数据，并在短时间内实现精确的匹配。'
          },
          {
            icon: '/image/TabT6ItemT2.svg',
            title: '个性化',
            text: '智能匹配系统能够根据用户的个人偏好和需求进行个性化推荐。通过对用户行为和习惯的分析，智能匹配系统可以提供更准确和符合用户期望的推荐结果。'
          },
          {
            icon: '/image/TabT6ItemT3.svg',
            title: '多维度',
            text: '智能匹配系统能够考虑多个维度进行匹配，从而提高匹配结果的准确性和全面性。'
          },
          {
            icon: '/image/TabT6ItemT4.svg',
            title: '持续优化',
            text: '智能匹配系统能够通过持续学习和优化来提高匹配效果。系统可以根据用户反馈和实际匹配结果进行自我调整和改进，从而不断提升匹配的准确性和用户满意度。'
          }
        ]}
      ></OneItemTwo>
    </div>
  );
}

export default App;
