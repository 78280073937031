import React, {useEffect} from 'react';
import Header from '@/components/CommonHeader/index'
import CommonIntroduceFour from "@/components/CommonIntroduceFour/index"
import CommonCarousel from '@/components/CommonCarousel/index'
import CommonFooterList from '../../../../components/CommonFooterList/index'
import styles from './index.module.less'

function App(props: any) {
    useEffect(() => {
      console.log('props', props.query)
    }, []);

    const CommonCarouselList = [
      [
        {
          icon: '/image/manageApiBlT1.png',
          title: '房产评估',
          text: '基于人工智能和大数据技术的智能化房产评估服务，可帮助用户快速、准确地了解房产的市场价值和价格水平，为房产交易提供可靠的决策依据。'
        },
        {
          icon: '/image/manageApiBlT2.png',
          title: '个人诉讼',
          text: '这项服务提供了一个便捷的方式，让个人能够了解自己是否涉及过法律纠纷、是否被起诉或起诉他人，并可以查看案件的基本信息和进展情况。'
        },
        {
          icon: '/image/manageApiBlT3.png',
          title: '企业诉讼',
          text: '这项服务提供了一个便捷的方式，了解自身是否涉及过法律纠纷、是否被起诉或起诉他人，并可以查看案件的详细信息、进展情况和法院判决结果。'
        }
      ],
      [
        // {
        //   icon: '/image/manageApiBlT4.png',
        //   title: '工商信息',
        //   text: '方便快捷地查询企业注册信息、经营状态、股东信息等相关工商信息的解决方案，以支持商业决策、合作伙伴选择和风险评估等方面的需求。'
        // },
        {
          icon: '/image/manageApiBlT5.png',
          title: '历史成交',
          text: '了解特定房产在过去的成交记录和价格走势。通过房产历史成交价查询服务，以及附近类似房产的价格水平。'
        },
        {
          icon: '/image/manageApiBlT6.png',
          title: '信用报告',
          text: '个人信用包含了个人的信用评分、信用历史记录、借贷记录、还款记录等信息。迅速地获取借款人或租客的信用状况，从而更准确地评估其信用风险。'
        }
      ]
    ]

    return (
      <div className={styles.home}>
        <div className={styles.header}>
          <Header
            title='金服API'
            subTitle={'一站式api调用平台，助力企业数字化升级'}
            bgImage={'/image/manageBg.png'}
            bgMain={'/image/manageApiMain.png'}
            titleColor={'#556987'}
            subTitleColor={'#556987'}
            style={{
              height: '560px',
            }}
          ></Header>
        </div>
        <div className={`wrapItem`}>
          <CommonIntroduceFour
            subTitle='Convenient and efficient data access and interaction solutions'
            title='便捷高效的数据访问与交互解决方案'
            text={
              <div>
                <p>用户可以方便地获取和使用各种数据资源，无需了解数据的具体存储和处理方式。金服API提供了标准化的接口和协议，使得数据的调用和集成变得简单和高效。用户可以通过API请求数据，并获得实时的响应和结果。</p>
                <p>对于企业而言，数据API服务能够带来许多优势。例如，可以提高企业的数据分析能力，帮助企业更好地了解客户需求和行为，从而制定出更加准确的商业决策。此外，这种服务还能提供个性化的数据分析和报告，为企业的业务发展和战略规划提供有力支持。</p>
              </div>
            }
            btnType='btn'
            image={'/image/manageApiT1.png'}
          ></CommonIntroduceFour>
        </div>
        <div className={`wrapItem`}>
          <CommonCarousel
            list={CommonCarouselList}
            {...props}
          ></CommonCarousel>
        </div>
        <div className={`wrapItem`}>
          <CommonFooterList {...props}></CommonFooterList>
        </div>
      </div>
    );
}

export default App;
