import React, { useEffect } from 'react';
import Router from './router';
import './App.css';
import { browserRedirect } from '@/utils/lib'

function App() {
  useEffect(() => {
    if (window.location.host.indexOf('gdszjf') !== -1) {
      document.title = '广东数字金服企业官网'
    }
    console.log(process.env,'111111')
    if (browserRedirect() === 'PE') {
      if (process.env.REACT_APP_ENV != 'test') {
        let href = window.location.origin.includes('www') ? window.location.origin.replace('www', 'wap') : `${window.location.protocol}//wap.${window.location.host}`
        window.location.href = href
      }else{
        window.location.href = 'http://www.szjf.k8s:1885/'
      }
    }
  }, [''])
  return (<div className={`${'black'}`}><Router /></div>);
}

export default App;
