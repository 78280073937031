import styles from './index.module.less'
import { leftList, rightList } from './menuData'
import CommonApply from "@/components/CommonApply";
import React, {useRef} from "react";

function Header(props: any) {
  const CommonApplyRef = useRef<any>(null)

  const itemClick = (item: any) => {
    if (item.onClick) {
      item.onClick(props)
    }
    if (item.href) {
      props.history.push(item.href)
    }
    const demo = document.getElementById('demo')
    if (demo) {
      demo.style.display = 'none'
      setTimeout(() => {
        demo.style.display = ''
      }, 10)
    }
  }

  return (
    <div className={styles.ProductServices}>
      <CommonApply ref={CommonApplyRef}></CommonApply>
      <div className={styles.left}>
        <ul>
          {
            leftList.map((el, index) => <li
              key={index}
              className={`flex-c`}
              onClick={() => el.onClick && el.onClick(CommonApplyRef.current)}
            >
              <img src={el.icon} alt=""/>
              <div className={styles.box}>
                <div className={styles.title}>{el.title}</div>
                <div className={styles.intro}>{el.intro}</div>
              </div>
            </li>)
          }
        </ul>
      </div>
      <div className={styles.right}>
        {
          rightList.map((el, index) => <div className={styles.item}>
            <div className={styles.titleBox}>
              <img src={el.icon} alt=""/>
              <span>{el.title}</span>
            </div>
            <ul>
              {
                el.list.map((val, j) => <li onClick={() => itemClick(val)}>
                  <div className={styles.title}>{val.title}</div>
                  <div className={styles.intro}>{val.intro}</div>
                </li>)
              }
            </ul>
          </div>)
        }
      </div>
    </div>
  );
}

export default Header;
