import styles from './index.module.less'
import {useState} from "react";

function Header(props: any) {

  const [ list ] = useState([
    {
      title: '产品服务',
      list: [
        { label: '一键查询系统', href: '/search' },
        { label: '金服助贷系统', href: '/LoanAssistance' },
        { label: '金服资方系统', href: '/thoseRepresentingCapital' },
        { label: '金服担保系统', href: '/security' },
        { label: '融经理', href: '/manage' },
        { label: '融经理助手', href: '/manageAssistant' },
        { label: '融代理', href: '/manageAgent' },
        { label: '金服数据API', href: '/manageApi' }
      ]
    },
    {
      title: '解决方案',
      list: [
        { label: '数字金服智能风控', href: '/riskControl?index=1' },
        { label: '个人信用及风险大数据', href: '/riskControl?index=2' },
        { label: '信用及风险咨询', href: '/riskControl?index=3' },
        { label: '数字化融资匹配', href: '/riskControl?index=4' },
        { label: '企业税务风险检测', href: '/riskControl?index=5' }
      ]
    },
    {
      title: '关于我们',
      list: [
        { label: '公司介绍', href: '/aboutUs' },
        { label: '服务保障', href: '/aboutUs?rollId=t1',
          onClick: () => {
            const rollEl = document.getElementById('t1')
            if (rollEl && props.location.pathname === '/aboutUs') {
              setTimeout(() => {
                document.body.scrollTop = rollEl.offsetTop;
                document.documentElement.scrollTop = rollEl.offsetTop;
              }, 20)
            } else {
              props.history.push('/aboutUs?rollId=t1')
            }
          }
        }
      ]
    }
  ])

  return (
    <div className={styles.Footer}>
      <div className={styles.box}>
        <div className={styles.left}>
          {
            list.map((el: any, index) => <div key={index} className={styles.item}>
              <div className={styles.title}>{el.title}</div>
              <ul>
                {
                  el.list.map((val: any, j: any) =>
                    <li
                      key={j}
                      onClick={() => val.onClick ? val.onClick() : props.history.push(val.href)}
                    >{val.label}</li>)
                }
              </ul>
            </div>)
          }
        </div>
        <div className={styles.right}>
          <div className={styles.title}>联系我们</div>
          <ul>
            <li>公司地址：广东省广州市海珠区江南大道中180号2613室</li>
            <li>商务合作：17521312438</li>
            <li>售前咨询：400-019-1551</li>
            <li>工作日：8:30-17:30</li>
          </ul>
          <div className={styles.qrBox}>
            <div>
              <p>数字金服公众号</p>
              <img src="/image/gzhCode.jpg" alt=""/>
            </div>
            <div>
              <p>客服微信(小金)</p>
              <img src="/image/kyCode.jpg" alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div className={`flex-bc ${styles.filingsBox}`}>
        {/*<div className={styles.logo}>*/}
        {/*  <img src="/image/filingsBoxlogo.png" alt=""/>*/}
        {/*</div>*/}
        <div className={styles.filings}>
          <span>
            ©2025 广东数字金服信息技术有限公司 版权所有 <a href="https://beian.miit.gov.cn/">粤ICP备19056887号</a> 公司地址：广州海珠区江南大道中180号富力·天域中心2613室
            {/*<a href="https://beian.miit.gov.cn/">粤ICP备19056887号</a>*/}
            {/*<a href="https://beian.miit.gov.cn/">粤ICP备19056887号-3</a>*/}
            {/*<a href="https://beian.miit.gov.cn/">粤ICP备19056887号-6</a>*/}
            {/*<a href="https://beian.miit.gov.cn/">粤ICP备19056887号-7</a>*/}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Header;
