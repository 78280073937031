import React, { useState } from 'react';
import OneItem from '../OneItem/index'
import OneItemTwo from '../OneItemTwo/index'
import styles from './index.module.less'

function App(props: any) {
  const { title, list, image, imageStyle } = props

  return (
    <div className={styles.IntroduceOne}>
      <OneItem
        title='企业税务风险检测'
        subTitle='对个人进行信用评估，识别风险人群，降低逾期可能'
        list={[
          { label: '近百个风控模型', intro: '系统具备近百个财税风险检测指标和风控模型，以实现对企业财务指标、税务指标、关联交易、发票进销项等信息的综合分析。' },
          { label: '提供了一站式解决方案', intro: '利用领先的人工智能和机器学习技术、风控规则库、全方位预警指标等功能，系统提供了一站式解决方案，以实现企业税务风险检测。' }
        ]}
        tip={"人工智能 / 精准获客 /  多维度刻画用户"}
        image='/image/TabT4.png'
      ></OneItem>
      <OneItemTwo
        title='方案亮点'
        subTitle='Highlights of the plan'
        list={[
          {
            icon: '/image/TabT5ItemT1.svg',
            title: '全面性',
            text: '企业税务风险检测系统能够对企业的财务指标、税务指标、关联交易、发票进销项等多个方面进行全面分析，确保没有遗漏和漏检，从而最大限度地减少税务风险。'
          },
          {
            icon: '/image/TabT5ItemT2.svg',
            title: '高效准确',
            text: '借助先进的人工智能和机器学习技术，企业税务风险检测系统能够快速、准确地分析大量的数据，实现高效的风险识别和预警。系统内置了财税风险检测指标和风控模型，利用这些指标和模型进行分析，进一步提高准确性。'
          },
          {
            icon: '/image/TabT5ItemT3.svg',
            title: '可定制化',
            text: '企业税务风险检测系统可以根据企业的具体需求进行定制化配置。系统可以根据企业的行业特点、规模大小等因素进行个性化设置，以适应不同企业的税务风险管理需求。'
          }
        ]}
      ></OneItemTwo>
    </div>
  );
}

export default App;
