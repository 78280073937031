import React, {useState, useImperativeHandle} from 'react';
import { Modal } from 'antd';
import styles from './index.module.less'
import { CloseCircleOutlined } from '@ant-design/icons';

function App(props: any, ref: any) {
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const cancel = () => {
    setIsModalOpen(false)
  }

  const open = () => {
    setIsModalOpen(true)
  }

  useImperativeHandle(ref, () => ({
    open
  }))

  return (
    <Modal
      open={isModalOpen}
      onOk={() => {}}
      onCancel={() => cancel()}
      width={382}
      footer={false}
      closable={false}
      keyboard={false}
      style={{ top: 'calc(25%)' }}
    >
      <div className={styles.commonApplyContent}>
        <div className={styles.title}>客服：小金</div>
        <div className={styles.subTitle}>请添加微信</div>
        <img src="http://www.gdszjf.com/qrcode/customer.jpg" alt=""/>
        {/*<div className={styles.end} onClick={() => setIsModalOpen(false)}>*/}
        {/*  <CloseCircleOutlined />*/}
        {/*</div>*/}
      </div>
    </Modal>
  );
}

export default React.forwardRef(App);
