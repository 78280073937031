import React, {useRef, useState, useImperativeHandle} from 'react';
import {Col, Form, Input, Modal, Row, Select} from 'antd';
import styles from './index.module.less'
import Encrypt from "encryptlong";
import {chinese, chinese2, chineseRegex, ruleMobPhone, rulePhone, ruleString} from '@/utils/regex'
import {post} from "@/utils/request";

function App(props: any, ref: any) {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const formRef = useRef<any>(null)

  const submit = async (data: any) => {
    var encryptor = new Encrypt()
    // let pubKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6gWYt-2R0SqNXLoMLdgEOwR133SL2C5lKgJ1zp35e95T9MXItg7qhfrS-XJE_YerSHDL13BSWZgBC628RB50jszh4wykn8wuWk9TFRdDt-9S8V9A-pgakSe8AnB8GlEG6-_jLSl2qXKmZqRwqzsCvFBcyitLn4ovo35ed1kQzct5M-UXqNfJmrtMK-XGDKQ_6rKH84op5hda9ly8t3x3lZHvPauqXMaba7nDYWeH3L7U4jQTHSNcamomc3TgdNqDDzqGRXobEMktULzidk62alecgWWLg3pe8-DqA9mGaonWo2Xm4krPa8wIjcw-sANEMLiWlUR2VtHRbvqPeiI5pwIDAQAB`
    let pubKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArL57GkHBuo0TXVMGPc04fTyLYGWGLVmqB35fHshvBkG1pXpkEhvyf2DI2Dnel+4h851OrSigEK1CNTi7gIZDSZDHtypg4nqpuh+k8Y6SWP5J/Zo53JnhEuyfDpXmkCYRKKKCByv03nOVMcRHAnP5uMEElG/UZfSQTGv7sMqHLBU2b+t6+Z3pTMfZnDWn5QBteopVqNJcI2J8/Mvonn56atq4slp/TZDLy7euo97qMzVOKGHw7Byu3q4UDuf0JnkoduOTCI1uJVXpYpzDxLndSC4WWqBQNkDb0/AQN5fcIMDEUblcO6uYVZ/Q4LK9GH8NsmxI/kdWnN6CCyd7LRON3wIDAQAB`
    encryptor.setPublicKey(pubKey)//设置公钥
    const ciphertextObj = JSON.parse(JSON.stringify(data))
    delete ciphertextObj.remark
    await post('home.addOrderRSA', {
      ...data,
      ciphertext: encryptor.encryptLong(JSON.stringify(ciphertextObj))
    })
    setIsModalOpen(false)
    formRef.current.resetFields()
  }

  const cancel = () => {
    formRef.current.resetFields()
    setIsModalOpen(false)
  }

  const open = () => {
    setIsModalOpen(true)
  }

  useImperativeHandle(ref, () => ({
    open
  }))

  return (
    <div className={styles.CommonApply}>
      <Modal
        open={isModalOpen}
        onOk={() => {}}
        onCancel={() => cancel()}
        width={648}
        footer={false}
        className={styles.modalClass}
        style={{ top: 'calc(15%)' }}
      >
        <div className={styles.commonApplyContent}>
          <div className={styles.title}>免费体验</div>
          <div className={styles.subTitle}>留下您的联系信息，24小时内专人回访，免费咨询解疑。</div>
          <div className={styles.form}>
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={(data) => submit(data)}
              onFinishFailed={() => {}}
              autoComplete="off"
            >
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <Form.Item
                    label="公司名称"
                    name="companyName"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="联系人姓名"
                    name="userName"
                    required={true}
                    rules={[({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('不能为空!'))
                        }
                        if (value.length <= 15) {
                          if (!chineseRegex(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(new Error('只能输入中文!'));
                          }
                        }
                        return Promise.reject(new Error('不能输入超过15个字!'));
                      }
                    })]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="联系人手机号"
                    name="phone"
                    required={true}
                    rules={[rulePhone]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="咨询类型"
                    name="type"
                    rules={[{ required: true, message: '请选择咨询类型' }]}
                  >
                    <Select
                      options={[
                        { label: '一键查询系统', value: '1' },
                        { label: '金服助贷系统', value: '2' },
                        { label: '融经理', value: '3' },
                        { label: '融代理', value: '4' },
                        { label: '融经理助手', value: '5' },
                        { label: '加盟', value: '7' },
                        { label: '其他', value: '6' }
                      ]}
                    />
                    {/*<Input />*/}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="备注"
                    name="remark"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className={styles.btns}>
              <div className={`flex-cc ${styles.cancelBtn}`} onClick={() => cancel()}>取消</div>
              <div className={`flex-cc ${styles.submitBtn}`} onClick={() => formRef.current.submit()}>提交资料</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default React.forwardRef(App);
