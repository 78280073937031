import React, {useRef, useState} from 'react';
import { Col, Row } from 'antd';
import styles from './index.module.less'
import CustomerServiceQR from "@/components/CustomerServiceQR";

function App(props: any) {
  const [ list ] = useState([
    { label: '房产评估', intro: '通过收集和分析房产的数据来计算出房产的价值', icon: '/image/searchIntroduceOneT1.svg' },
    { label: '信用报告', intro: '基于大数据技术的征信平台，用于查询个人信用状况。', icon: '/image/searchIntroduceOneT2.svg' },
    { label: '企业诉讼', intro: '帮助用户快速查询和了解企业的诉讼信息和案件进展情况。', icon: '/image/searchIntroduceOneT3.svg' },
    { label: '历史成交', intro: '供用户查询和了解过去的房屋成交价格，帮助用户做出投资决策。', icon: '/image/searchIntroduceOneT4.svg' },
    { label: '个人诉讼', intro: '帮助用户快速查询和了解客户的诉讼信息和案件进展情况。', icon: '/image/searchIntroduceOneT5.svg' }
  ])
  const CustomerServiceQRRef = useRef<any>(null)

  return (
    <div className={styles.IntroduceOne}>
      <CustomerServiceQR ref={CustomerServiceQRRef}></CustomerServiceQR>
      <div className={styles.display}></div>
      <h6>Diversified risk identification</h6>
      <h5>多元化数据</h5>
      <div className={styles.wrap}>
        <div className={styles.right}>
          <Row gutter={[40, 30]}>
            {
              list.map((el, index) => <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div key={index} className={`flex ${styles.item}`}>
                  <img src={el.icon} alt="" className={styles.icon}/>
                  <div>
                    <p className={styles.title}>{el.label}</p>
                    <p className={styles.intro}>{el.intro}</p>
                  </div>
                </div>
              </Col>)
            }
          </Row>
          <div className={`flex-cc ${styles.btn}`} onClick={() => CustomerServiceQRRef.current.open()}>立即咨询 <img src="/image/ArrowRight.png" alt=""/></div>
        </div>
        <div className={styles.left} style={{background: 'url(/image/searchRightOne.png) 100% no-repeat'}}></div>
      </div>
    </div>
  );
}

export default App;
