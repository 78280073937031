import React, {useEffect, useState} from 'react';
import styles from './index.module.less'
import { Col, Row } from 'antd';

function App(props: any) {
  const [ list ] = useState([
    {
      icon: '/image/IntroduceOnet1.png',
      title: '智能尽调解读企业背景',
      intro: '进行合作、投资或收购等商业交易前，对要合作的企业的相关信息进行全面、深入的调查和分析，以了解该企业的背景和情况，风险和机会，从而做出正确的商业决策。'
    },
    {
      icon: '/image/IntroduceOnet2.png',
      title: '金融产品精准匹配',
      intro: '使用人工智能、大数据和机器学习等技术，分析借款人的个人信息、信用记录、财务状况等多维度数据，找到最适合借款人需求的信贷产品并进行推荐。'
    },
    {
      icon: '/image/IntroduceOnet3.png',
      title: '财税尽调专业服务',
      intro: '针对企业、个体工商户、个人创业者等提供的，旨在帮助客户更好地管理财务和税务事务，规范运营，提高经济效益，同时也符合法律法规和税收政策规定。'
    },
    {
      icon: '/image/IntroduceOnet4.png',
      title: '经营数据搜集服务',
      intro: '采集、整理、分析和报告企业的各种经营数据，如销售额、利润、成本、库存、客户满意度等指标。也可以来自外部的市场调研、竞争分析等来源。'
    },
    {
      icon: '/image/IntroduceOnet5.png',
      title: '税务风险审查服务',
      intro: '通过税务风险检测服务，企业可以及时了解自身的税务风险情况，避免违法违规，遵守税收政策和相关法律法规，有效降低税务风险，保障企业的正常运营和发展。'
    },
    {
      icon: '/image/IntroduceOnet6.png',
      title: '资产价值评估服务',
      intro: '帮助企业或个人了解自己的资产价值，从而更好地管理和利用资产。服务提供商会根据不同的资产类型和市场情况，采用不同的评估方法和技术，对资产进行评估，并提供评估报告。'
    }
  ])

  return (
    <div className={styles.IntroduceOne}>
      <h5>全面支持企业服务机构进行资信风险控制</h5>
      <h6>作为专业、独立的财税数据技术服务提供商，我们的使命是助力财税业务实现数字化升级</h6>
      <Row gutter={[10, 10]}>
        {
          list.map(el => <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <div className={styles.item}>
              <img src={el.icon} alt=""/>
              <div className={styles.title}>{el.title}</div>
              <div className={styles.intro}>{el.intro}</div>
            </div>
          </Col>)
        }
      </Row>
    </div>
  );
}

export default App;
