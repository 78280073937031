import React, { useState } from "react";
import { Form, Input, Button, Space } from "antd";
import styles from './index.module.less'


const RegistrationForm: React.FC = (props: any) => {
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    type FieldType = {
        phone?: string;
        code?: string;
    };

    const [countdown, setCountdown] = useState(60);
    const [isCounting, setIsCounting] = useState(false);
    const getCode = () => {
        if (!isCounting) {
            setIsCounting(true);
            // 模拟发送验证码的异步操作
            setTimeout(() => {
                setIsCounting(false);
                setCountdown(60);
            }, 60000);
        }
    }

    const goLogin = () => {
        props.demo('login')
    }

    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isCounting) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [isCounting]);


    return (
        <div className={styles.sign} id="sign">
            <div className={styles.title}>免费注册</div>
            <div className={styles.form}>
                <Form
                    name="basic"
                    style={{ width: 380 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        label=""
                        name="phone"
                        rules={[{ required: true, message: '请输入手机号码' }]}
                    >
                        <Input placeholder="请输入手机号码" />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label=""
                        name="code"
                        rules={[{ required: true, message: '请输入验证码' }]}
                    >
                        <Space.Compact>
                            <Input placeholder="请输入验证码" className={styles.input} maxLength={6} />
                            <Button type="text" style={{ position: 'absolute', right: 2, color: '#3966FF', top: 8, zIndex: 999 }} onClick={getCode} disabled={isCounting}>{isCounting ? `${countdown}秒后重新获取` : "获取验证码"}</Button>
                        </Space.Compact>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            注册
                        </Button>
                    </Form.Item>
                </Form>
            </div>

            <div className={styles.bottom}>
                已有账号？
                <span onClick={goLogin}>去登录</span>
            </div>
        </div>
    );
};


export default RegistrationForm;
