import React, {useEffect} from 'react';
import Header from './components/Header/index'
import IntroduceOne from "@/components/CommonIntroduceOne/index"
import IntroduceTwo from '@/components/CommonIntroduceTwo/index'
import IntroduceThree from '@/components/CommonIntroduceThree/index'
import CommonFooterList from '../../../../components/CommonFooterList/index'
import styles from './index.module.less'

function App(props: any) {
    useEffect(() => {
      console.log('query', props)
    }, []);

    return (
      <div className={styles.home}>
        <Header
          title='一键查询系统'
          subTitle={'快速获取金融、地产等数据'}
          bgImage={'/image/searchMainBg.png'}
          bgMain={'/image/searchMain.png'}
          titleColor={'#556987'}
          subTitleColor={'#556987'}
          style={{
            height: '560px',
          }}
        ></Header>
        <div className={`wrapItem`}>
          <IntroduceOne></IntroduceOne>
        </div>
        <div className={`wrapItem`}>
          <IntroduceTwo
            title='行业现状'
            subTitle='Industry status'
            list={[
              {
                icon: '/image/IndustrystatusItemT1.svg',
                intro: '对于新兴市场或特定行业细分领域的数据，可能很难获得足够的样本来进行有意义的分析'
              },
              {
                icon: '/image/IndustrystatusItemT2.svg',
                intro: '很多金融数据的收集和整理是相对缓慢的过程，导致缺乏实时性。'
              },
              {
                icon: '/image/IndustrystatusItemT3.svg',
                intro: '数据的不一致性和可靠性问题'
              }
            ]}
          ></IntroduceTwo>
        </div>
        <div className={`wrapItem`}>
          <IntroduceThree
            title='系统优势 ｜ System Advantages'
            list={[
              { label: '高效性', intro: '能够以快速和高效的方式访问和检索大量数据。用户可以通过输入关键词、条件或参数来获取他们所需的特定数据，而无需手动查找和整理信息。' },
              { label: '准确性', intro: '我们的系统与可靠的数据源和数据库相连接，确保数据的准确性和完整性。用户可以放心地使用这些数据来做出决策和进行分析，而不必担心数据的可靠性问题。' },
              { label: '可定制性', intro: '用户可以选择他们想要查询的特定数据字段、筛选条件、排序方式等，以便获得定制化的数据结果。' }
            ]}
            image='/image/IntroduceThreeBg.png'
          ></IntroduceThree>
        </div>
        <div className={`wrapItem`}>
          <CommonFooterList {...props}></CommonFooterList>
        </div>
      </div>
    );
}

export default App;
