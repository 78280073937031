import React from 'react';
import styles from './index.module.less'
import { Col, Row } from 'antd';

function App(props: any) {
    return (
      <div className={styles.ItemOne}>
        <div className={styles.header}>
          <div className={styles.title}>以下是我们在支持客户方面所提供的服务</div>
          <div className={styles.subTitle}>We provide customers with the following support</div>
        </div>
        <div className={styles.content}>
          <img src="/image/cooperationT1.png" className={styles.cooperationT1}/>
          <Row gutter={[20, 20]}>
            {
              [
                {
                  title: '先进产品',
                  intro: '专业团队，优秀工程师，提供优质技术支持',
                  icon: '/image/cooperationChannelsT1.png',
                  showIcon: '/image/cooperationChannelsT1Show.svg'
                },
                {
                  title: '专业服务',
                  intro: '专注客户需求，专业高效，提供优质解决方案。',
                  icon: '/image/cooperationChannelsT2.svg',
                  showIcon: '/image/cooperationChannelsT2Show.svg'
                },
                {
                  title: '数据支持',
                  intro: '数据支持，全方位服务，助力客户决策。',
                  icon: '/image/cooperationChannelsT3.svg',
                  showIcon: '/image/cooperationChannelsT3Show.svg'
                }
              ].map(el => <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <div className={styles.item}>
                  <div className={`flex-cc ${styles.heade}`}>
                    <img src={el.icon} alt="" className={styles.icon}/>
                    <img src={el.showIcon} alt="" className={styles.showIcon}/>
                  </div>
                  <div className={styles.title}>{el.title}</div>
                  <div className={styles.intro}>{el.intro}</div>
                </div>
              </Col>)
            }
          </Row>
        </div>
      </div>
    );
}

export default App;
