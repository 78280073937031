import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.less'
import Sign from '../../components/sign/index'
import Login from '../../components/login/index'
import { Divider } from 'antd';

function App(props: any) {
    useEffect(() => {
        console.log('query', props)
    }, ['']);

    const goLogin = (e: any) => {
        console.log('loginType', e)
    }

    const wechatLogin = () => {
        console.log('wechatLogin')
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                {/* 左边内容 */}
            </div>
            <div className={styles.right}>
                <div className={styles.top}>
                    {/* <Sign /> */}
                    <Login />

                    <div className={styles.divider}>
                        <span>其他注册方式</span>
                    </div>

                    <div className={styles.wechat} onClick={wechatLogin}>
                        <div className={styles.img}></div>
                        {/* <img src="../../img/luffy.png" alt="" className={styles.img} /> */}
                        <span>微信扫码注册</span>
                    </div>
                </div>
                <div className={styles.bottom}>
                    登录即表示您已阅读并同意<span className={styles.serve}>服务条款</span>
                </div>
            </div>
        </div>
    );
}

export default App;
