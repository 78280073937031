import React, {useEffect} from 'react';
import styles from './index.module.less'
import { Col, Row } from 'antd';

function App(props: any) {
  const list = [
    { label: '售前支持', icon: '/image/aboutUsItemTwoT1.svg', intro: '我们提供售前方案支持，包括技术、产品等专业的咨询服务，以确保客户在购买前能够充分了解产品和服务，并做出明智的决策。' },
    { label: '专属服务', icon: '/image/aboutUsItemTwoT2.svg', intro: '我们提供1V1专属服务，全天候7x24小时响应，5分钟内快速响应，并在30分钟内给出反馈，确保客户能够及时解决突发问题。' },
    { label: '产品培训', icon: '/image/aboutUsItemTwoT3.svg', intro: '我们提供产品培训和技术交流，以帮助客户快速掌握产品使用方法，提高客户在使用过程中的效率和满意度。' },
    { label: '定制化支持', icon: '/image/aboutUsItemTwoT4.svg', intro: '我们拥有专业的研发团队，能够满足客户的定制化需求，并提供高效交付实施和专业的运维支持，以确保项目的成功实施和稳定运行。' }
  ]

  return (
    <div className={styles.ItemTwo} id={'t1'}>
      <div className={`${styles.conetne}`}>
        <div className={styles.left}>
          <div className={styles.oneItem}>
            <img src="/image/ItemTwoTitleBg.png" alt="" className={styles.ItemOneTitleBg}/>
            <div className={styles.display}></div>
            <h5>服务保障</h5>
            <h6>Service guarantee</h6>
            {/*<p>广东数字金服信息技术有限公司（简称：数字金服）是一家金融科技服务公司。成立以来，在金融互联网耕耘数载，把握金融行业上下游痛点，整理行业大数据，结合云服务和区块链等技术，诊断出金融行业痛症，自主研发“数字金服SaaS平台”，为金融行业提供优质服务，为用户提供有力的金融解决方案。<br/>*/}
            {/*  数字金服是一家年轻有活力的企业，创新性地把“互联网+金融”的运行模式从探讨到落地，从落地到实操，专注围绕金融产业，整合行业公司，为金融产业提供系统赋能。致力把“数字金服SaaS平台”培育成具有渠道、场景、产品、风控、数据等服务功能的一体化金融管理系统，为金融行业走向数字金融时代加速。</p>*/}
          </div>
        </div>
        <div className={styles.list}>
          <Row gutter={[20, 0]}>
            {
              list.map(el => <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className={styles.item}>
                  <img src={el.icon} alt=""/>
                  <div className={styles.title}>{el.label}</div>
                  <div className={styles.intro}>{el.intro}</div>
                </div>
              </Col>)
            }
          </Row>
        </div>
      </div>
    </div>
  );
}

export default App;
