import React, {useEffect, useRef} from 'react';
import styles from './index.module.less'
import CommonApply from '@/components/CommonApply/index'
import CustomerServiceQR from '@/components/CustomerServiceQR/index'

function App(props: any) {
  const CommonApplyRef = useRef<any>(null)
  const CustomerServiceQRRef = useRef<any>(null)

  useEffect(() => {
    setTimeout(() => {
      const homeBg: any = document.getElementById('homeBg')
      if (homeBg) {
        console.log('homeBg', homeBg)
        homeBg.play()
      }
    }, 1000)
  }, ['']);

  return (
    <div className={styles.header}>
      <CommonApply ref={CommonApplyRef}></CommonApply>
      <CustomerServiceQR ref={CustomerServiceQRRef}></CustomerServiceQR>
      <div className={`header flex-cc ${styles.headerBox}`}>
        <div className={`flex-cc ${styles.box}`}>
          <h4>金融行业  解决方案</h4>
          <h5>助力企业实现管理信息化、决策科学化，全面提升金融企业核心竞争力</h5>
          <div className={`flex-c ${styles.btnBox}`}>
            <div className={`flex-cc ${styles.btn} ${styles.pr}`} onClick={() => CommonApplyRef.current.open()}>免费体验</div>
            <div className={`flex-cc ${styles.btn}`} onClick={() => CustomerServiceQRRef.current.open()}>立即咨询</div>
          </div>
        </div>
      </div>
      {/*<iframe src={'/image/homeBg.mp4'}></iframe>*/}
      <video id={'homeBg'} autoPlay={true} muted loop={true}>
        <source src={'/image/homeBg.mp4'} type="video/mp4" />
      </video>
    </div>
  );
}

export default App;
