import React, {useEffect} from 'react';
import Header from '@/components/CommonHeader/index'
import IntroduceOne from "@/components/CommonIntroduceOne/index"
import IntroduceTwo from '@/components/CommonIntroduceTwo/index'
import IntroduceThree from '@/components/CommonIntroduceThree/index'
import CommonFooterList from '../../../../components/CommonFooterList/index'
import styles from './index.module.less'
import CommonIntroduceFour from "@/components/CommonIntroduceFour";

function App(props: any) {
    useEffect(() => {
      console.log('query', props)
    }, []);

    return (
      <div className={styles.home}>
        <div className={styles.header}>
          <Header
            title='金服资方系统'
            subTitle={'管理和监控借贷资金的平台。'}
            bgImage={'/image/thoseRepresentingCapitalBg.png'}
            bgMain={'/image/thoseRepresentingCapitalMain.png'}
            style={{
              height: '560px',
            }}
          ></Header>
        </div>
        <div className={`wrapItem`}>
          <CommonIntroduceFour
            subTitle='Online fund management and monitoring'
            title='资金管理、监控线上化'
            text='资方系统是一个专业的平台，为用户提供全面的风险组合管理和监控功能。该系统通过整合多个投资渠道和数据源，帮助资方实时了解风险控制。资方系统具备智能化的分析和报告功能，能够快速评估投资项目的潜在风险和收益，并提供决策支持。通过资方系统，用户可以更加高效地优化投资策略，实现更好的资金回报。'
            image={'/image/LoanAssistanceT1Img.png'}
            btnType={'btn'}
          ></CommonIntroduceFour>
        </div>
        <div className={`wrapItem`}>
          <IntroduceTwo
            title='行业现状'
            subTitle='Industry status'
            list={[
              {
                icon: '/image/IndustrystatusItemT1.svg',
                intro: '在缺乏资方系统的情况下，用户往往难以获得全面的投资组合信息和实时的投资情况'
              },
              {
                icon: '/image/IndustrystatusItemT2.svg',
                intro: '在金融行业中，缺乏资方系统使得数据分散难以整合，对投资组合的综合分析和监控变得困难'
              },
              {
                icon: '/image/IndustrystatusItemT3.svg',
                intro: '没有资方系统的支持，用户面临风险控制的挑战。且难以及时发现潜在的风险和问题'
              }
            ]}
          ></IntroduceTwo>
        </div>
        <div className={`wrapItem`}>
          <IntroduceThree
            title='系统优势 ｜ System Advantages'
            list={[
              { label: '决策支持', intro: '资方系统使用智能化的数据分析和算法模型，能够提供精准的金融建议和决策支持。通过系统的分析和预测功能，提高金融决策的准确性和效率。' },
              { label: '数据安全和隐私保护', intro: '资方系统注重数据安全和隐私保护，采用高级加密技术和安全措施，保护客户的敏感信息。用户可以放心使用系统，不必担心数据泄露或被滥用的风险，更加安心地进行业务操作。' },
              { label: '个性化提醒', intro: '资方系统可以根据用户的偏好和需求，提供个性化的提醒和通知功能。用户可以设置关键的风险变化的提醒，及时了解客户动态。' }
            ]}
            image='/image/IntroduceThreeBgtBg2.png'
          ></IntroduceThree>
        </div>
        <div className={`wrapItem`}>
          <CommonFooterList {...props}></CommonFooterList>
        </div>
      </div>
    );
}

export default App;
