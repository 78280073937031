import React, {useEffect, useState} from 'react';
import styles from './index.module.less'
import { Col, Row } from 'antd';

function App(props: any) {
  const [ list ] = useState([
    {
      icon: '/image/IntroduceThreet1.png',
      title: '一键查询系统',
      intro: '一键查询系统包含多种维度数据（诉讼信息、工商信息、评估数据、历史成交价等）它允许用户通过简单的界面输入查询条件，并根据条件对数据进行筛选、排序、聚合和可视化展示，帮助用户更好地理解和分析数据，做出准确的决策'
    },
    {
      icon: '/image/IntroduceThreet2.png',
      title: '金服资方系统',
      intro: '资方系统是一个专业的平台，为用户提供全面的风险组合管理和监控功能。资方系统具备智能化的分析和报告功能，通过资方系统，用户可以更加高效地优化投资策略，实现更好的资金回报'
    },
    {
      icon: '/image/IntroduceThreet3.png',
      title: '金服助贷系统',
      intro: '该系统是一个集成了贷前、贷中、贷后所有功能的智能化风控与营销平台。它利用大数据和人工智能技术，提高金融机构的信贷风险管理水平，同时为借款人提供更便捷的贷款服务'
    },
    {
      icon: '/image/IntroduceThreet4.png',
      title: '融经理助手',
      intro: '融经理助手app是一款移动端的智能数据查询工具，通过简单输入查询条件，即可快速筛选并展示大量数据。它适用于多种机构，如银行、小贷、担保公司等，帮助用户随时随地轻松掌握数据信息，做出明智的决策'
    }
  ])

  return (
    <div className={styles.IntroduceThree}>
      <div className={styles.display}></div>
      <h5>了解更多数字金服产品</h5>
      <Row gutter={[10, 10]}>
        {
          list.map(el => <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <div className={styles.item}>
              <img src={el.icon} alt=""/>
              <div className={styles.itemContent}>
                <div className={styles.title}>{el.title}</div>
                <div className={styles.intro}>{el.intro}</div>
              </div>
              <div className={styles.mork}>
                <div className={`flex-c`}>
                  了解更多
                  <img src="/image/IntroduceThreeMork.png" alt=""/>
                </div>
              </div>
            </div>
          </Col>)
        }
      </Row>
    </div>
  );
}

export default App;
