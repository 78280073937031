import React, { Suspense } from 'react';
// @ts-ignore
import { Switch, HashRouter, Route, Router } from 'react-router-dom'
import { createBrowserHistory } from "history";
import Layout from '../layout/index'
import FrontendAuth from './FrontendAuth'
import { routerObj } from '../views'
const customHistory = createBrowserHistory()


function router(props: any) {
  console.log('props', props)
  return (
    // <HashRouter>
    //   <Suspense fallback={<div>Loading...</div>}>
    //     <Switch>
    //       {/* @ts-ignore */}
    //       <Route path='/' component={Layout} />
    //     </Switch>
    //   </Suspense>
    // </HashRouter>
    <Router history={customHistory}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {/* @ts-ignore */}
          <Route path='/' component={Layout} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default router;
