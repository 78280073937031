import React, {useEffect, useRef} from 'react';
import styles from './index.module.less'
import CustomerServiceQR from "@/components/CustomerServiceQR";

function App(props: any) {
  const { title, subTitle, image, bgImage, bgMain, style, btn = true, RightView, titleColor, subTitleColor } = props
  const CustomerServiceQRRef = useRef<any>(null)

  return (
    <div className={styles.header} style={{background: `url(${bgImage || '/image/searchHeaderBg.png'}) 100% no-repeat`, ...style}}>
      <CustomerServiceQR ref={CustomerServiceQRRef}></CustomerServiceQR>
      <div className={`header flex-c ${styles.headerBox}`} style={{background: `url(${bgMain}) 100% no-repeat`, ...style}}>
        <div className={`flex-bc ${styles.headerBoxCp}`}>
          <div className={`${styles.box}`}>
            <h4 style={{ color: titleColor }}>{title}</h4>
            <h5 style={{ color: subTitleColor }}>{subTitle}</h5>
            <div className={`flex-c ${styles.btnBox}`}>
              {
                btn && <div className={`flex-cc ${styles.btn}`} onClick={() => CustomerServiceQRRef.current.open()}>立即咨询</div>
              }
            </div>
          </div>
          {
            RightView && <div>{RightView}</div>
          }
          <img src={image} alt=""/>
        </div>
      </div>
      {/*<div className={styles.data} style={{background: 'url(/image/searchDataBg.png)'}}>*/}
      {/*  <ul className={`flex-cc`}>*/}
      {/*    <li className={`flex-cc`}>*/}
      {/*      <div className={styles.nub}>95%</div>*/}
      {/*      <div className={styles.txt}>数据准确度</div>*/}
      {/*    </li>*/}
      {/*    <li className={`flex-cc`}>*/}
      {/*      <div className={styles.nub}>70%</div>*/}
      {/*      <div className={styles.txt}>提升审批效率</div>*/}
      {/*    </li>*/}
      {/*    <li className={`flex-cc`}>*/}
      {/*      <div className={styles.nub}>99%</div>*/}
      {/*      <div className={styles.txt}>数据完整度</div>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</div>*/}
    </div>
  );
}

export default App;
