import React, {useEffect} from 'react';
import Header from '@/components/CommonHeader/index'
import CommonIntroduceFour from "@/components/CommonIntroduceFour/index"
import IntroduceTwo from '@/components/CommonIntroduceTwo/index'
import IntroduceThree from '@/components/CommonIntroduceThree/index'
import CommonFooterList from '../../../../components/CommonFooterList/index'
import styles from './index.module.less'

function App(props: any) {
    useEffect(() => {
      console.log('query', props)
    }, []);

    return (
      <div className={styles.home}>
        <div className={styles.header}>
          <Header
            title='融经理'
            subTitle={'给用户提供多种授权通道'}
            bgImage={'/image/manageBg.png'}
            bgMain={'/image/manageMain.png'}
            titleColor={'#556987'}
            subTitleColor={'#556987'}
            style={{
              height: '560px',
            }}
          ></Header>
        </div>
        <div className={`wrapItem`}>
          <CommonIntroduceFour
            subTitle='Online authorization process'
            title='授权流程线上化'
            text='基于互联网的应用程序，旨在提供便捷的授权管理解决方案。它允许用户通过在线平台进行授权操作，无需面对面的沟通和繁琐的文件处理，融经理提供了一安全的平台。融经理还提供了实时的权限审批和记录功能，用户可以方便地跟踪和监控权限的使用情况，确保安全性和合规性。'
            image={'/image/manageT1.png'}
            btnType={'btn'}
            qrText={'进入小程序'}
          ></CommonIntroduceFour>
        </div>
        <div className={`wrapItem`}>
          <IntroduceTwo
            title='行业现状'
            subTitle='Industry status'
            list={[
              {
                icon: '/image/IndustrystatusItemT1.svg',
                intro: '传统金融行业中，通过填写纸质文件和手签的方式进行容易出现遗漏、损坏和数据丢失的问题。'
              },
              {
                icon: '/image/IndustrystatusItemT2.svg',
                intro: '传统金融行业的授权流程通常需要等待人工审核和批准，导致授权过程耗时较长。'
              },
              {
                icon: '/image/IndustrystatusItemT3.svg',
                intro: '传统授权方式容易出现身份验证和数据安全方面的隐患。难以保证授权的真实性和完整性。'
              }
            ]}
          ></IntroduceTwo>
        </div>
        <div className={`wrapItem`}>
          <IntroduceThree
            title='应用优势 ｜ Application advantages'
            list={[
              { label: '提高工作效率', intro: '线上授权功能使金融机构能够实现快速、实时的授权过程。用户可以通过在线平台完成授权申请，无需繁琐的文件处理和邮寄流程，省去了等待和传输时间。' },
              { label: '提升安全性和减少风险', intro: '线上授权功能通过采用安全的身份认证和加密技术，保证了授权过程的安全性和保密性。用户可通过多因素身份验证或使用数字签名等方式，确保授权的真实性和不可篡改性。' },
              { label: '降低成本', intro: '线上授权功能可以减少线下授权流程中的人工干预和审核成本，实现授权流程的自动化和数字化。这可以降低金融机构的成本，提高经营效率和盈利能力。' }
            ]}
            image='/image/manageT2.png'
            imageStyle={{
              maxWidth: '700px',
              backgroundSize: '100%'
            }}
          ></IntroduceThree>
        </div>
        <div className={`wrapItem`}>
          <CommonFooterList {...props}></CommonFooterList>
        </div>
      </div>
    );
}

export default App;
