import React, {useEffect} from 'react';
import Header from '@/components/CommonHeader/index'
import IntroduceOne from "@/components/CommonIntroduceOne/index"
import IntroduceTwo from '@/components/CommonIntroduceTwo/index'
import IntroduceThree from '@/components/CommonIntroduceThree/index'
import CommonFooterList from '../../../../components/CommonFooterList/index'
import styles from './index.module.less'
import CommonIntroduceFour from "@/components/CommonIntroduceFour";

function App(props: any) {
    useEffect(() => {
      console.log('query', props)
    }, []);

    return (
      <div className={styles.home}>
        <div className={styles.header}>
          <Header
            title='金服担保系统'
            subTitle={'为借款人提供担保服务和贷款准入率的平台。'}
            bgImage={'/image/securityBg.png'}
            bgMain={'/image/securityMain.png'}
            style={{
              height: '560px',
            }}
          ></Header>
        </div>
        <div className={`wrapItem`}>
          <CommonIntroduceFour
            subTitle='Standardization of employee processes'
            title='担保业务、风控线上化'
            text='金服担保系统带来了许多优势和便利。首先，线上平台可以提供更快速、便捷的服务，用户可以随时随地通过网络进行担保业务的申请和办理，无需受时间和地点的限制。其次，线上平台可以实现信息的集中管理和共享，减少了繁琐的纸质文件处理和传递，提高了业务处理的效率和准确性。此外，线上平台还可以通过智能化的数据分析和风险评估，为用户提供更精准的担保服务和决策支持。'
            image={'/image/LoanAssistanceT1Img.png'}
            btnType={'btn'}
          ></CommonIntroduceFour>
        </div>
        <div className={`wrapItem`}>
          <IntroduceTwo
            title='行业现状'
            subTitle='Industry status'
            list={[
              {
                icon: '/image/IndustrystatusItemT1.svg',
                intro: '线下担保业务存在时间和地点的限制，需要双方当事人集中在同一地点进行担保手续'
              },
              {
                icon: '/image/IndustrystatusItemT2.svg',
                intro: '线下担保业务通常需要涉及大量的人工操作和文件处理过程'
              },
              {
                icon: '/image/IndustrystatusItemT3.svg',
                intro: '在线下担保业务中，涉及大量的敏感信息，如个人身份证明、财务状况等'
              }
            ]}
          ></IntroduceTwo>
        </div>
        <div className={`wrapItem`}>
          <IntroduceThree
            title='系统优势 ｜ System Advantages'
            list={[
              { label: '提高效率和便捷性', intro: '可以提高操作和处理效率，减少传统线下流程中的繁琐手续和时间成本。可以通过在线平台完成担保申请、资料提交和审核等流程。' },
              { label: '提供安全和隐私保护', intro: '在线上平台进行担保业务可以提供更高的数据安全和隐私保护。在线提交的文件和资料可以进行加密和安全传输，减少了信息泄露和滥用的风险。' },
              { label: '提升信息透明度和可追溯性', intro: '以提供更高的信息透明度和可追溯性。随时查看申请和审核的结果。在线平台还可以提供更详尽的担保信息和相关条款，使当事人更加清晰地了解担保条件和责任。' }
            ]}
            image='/image/IntroduceThreeBgtBg3.png'
            rightStyle={{
              width: '600px',
              marginRight: '50px'
            }}
          ></IntroduceThree>
        </div>
        <div className={`wrapItem`}>
          <CommonFooterList {...props}></CommonFooterList>
        </div>
      </div>
    );
}

export default App;
