import styles from './index.module.less'
import CommonApply from "@/components/CommonApply";
import CustomerServiceQR from "@/components/CustomerServiceQR";
import React, {useRef} from "react";

function Header(props: any) {
  const CommonApplyRef = useRef<any>(null)
  const CustomerServiceQRRef = useRef<any>(null)

  return (
    <div className={styles.Apply}>
      <CommonApply ref={CommonApplyRef}></CommonApply>
      <CustomerServiceQR ref={CustomerServiceQRRef}></CustomerServiceQR>
      <img src="/image/ApplyBgTop.png" alt="" className={styles.top}/>
      <img src="/image/ApplyBgBottom.png" alt="" className={styles.Bottom}/>
      <div className={styles.box}>
        <div className={styles.title}>立即申请 了解产品 免费试用</div>
        <div className={styles.intro}>更多产品等你体验</div>
        <div className={styles.btns}>
          <div className={`flex-cc ${styles.btn}`} onClick={() => CommonApplyRef.current.open()}>免费体验</div>
          <div className={`flex-cc ${styles.btn}`} onClick={() => CustomerServiceQRRef.current.open()}>立即咨询</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
