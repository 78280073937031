import React from 'react';
import styles from './index.module.less'
import Header from "@/components/CommonHeader";

function App(props: any) {
  return (
    <div className={styles.ItemOne}>
      <div className={`flex-c ${styles.conetne}`}>
        <div className={styles.left}>
          <div className={styles.oneItem}>
            <img src="/image/ItemOneTitleBg.png" alt="" className={styles.ItemOneTitleBg}/>
            <div className={styles.display}></div>
            <h5>广东数字金服信息技术有限公司</h5>
            <h6>Guangdong Digital Financial Information Technology</h6>
            <p>广东数字金服信息技术有限公司（简称：数字金服）是一家金融科技服务公司。成立以来，在金融互联网耕耘数载，把握金融行业上下游痛点，整理行业大数据，结合云服务和区块链等技术，诊断出金融行业痛症，自主研发“数字金服SaaS平台”，为金融行业提供优质服务，为用户提供有力的金融解决方案。<br/>
              数字金服是一家年轻有活力的企业，创新性地把“互联网+金融”的运行模式从探讨到落地，从落地到实操，专注围绕金融产业，整合行业公司，为金融产业提供系统赋能。致力把“数字金服SaaS平台”培育成具有渠道、场景、产品、风控、数据等服务功能的一体化金融管理系统，为金融行业走向数字金融时代加速。</p>
          </div>
        </div>
        <div className={styles.right}>
          <img src="/image/ItemOneT1Img.png" alt=""/>
        </div>
      </div>
    </div>
  );
}

export default App;
