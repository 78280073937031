import styles from './index.module.less'
import ProductServices from './components/ProductServices/index'
import { useEffect, useState } from "react";

function Header(props: any) {
  const [scrollTop, setScrollTop] = useState(0)
  const [mouseMoveChilrenShow, setMouseMoveChilrenShow] = useState(false)
  const { location: { pathname } } = props;

  useEffect(() => {
    console.log('eqwewqewq', props)
    const scrollTopPx = document.documentElement.scrollTop || document.body.scrollTop;
    setScrollTop(scrollTopPx)
    window.addEventListener('scroll', () => {
      const scrollTopPx = document.documentElement.scrollTop || document.body.scrollTop;
      setScrollTop(scrollTopPx)
    }, true)
  }, [])

  const list = [
    { label: '首页', href: '/' },
    { label: '产品服务', chilren: ProductServices, chilrenRoutes: ['/search', '/LoanAssistance', '/thoseRepresentingCapital', '/security', '/manage', 'manageAssistant', '/manageAgent', '/manageApi?index=0', '/manageApi?index=1'] },
    { label: '解决方案', href: '/riskControl' },
    { label: '合作渠道', href: '/cooperationChannels' },
    { label: '关于我们', href: '/aboutUs' }
  ]
  const blackRoutes = ['/search', '/manage', '/manageAssistant', '/manageAgent', '/manageApi']

  return (
    <div id="header" className={`${scrollTop >= 10 || mouseMoveChilrenShow ? styles.show : ''} ${styles.heade} ${blackRoutes.find(val => val === props.location.pathname) ? styles.black : ''}`}>
      <div className={styles.logoBox}>
        <img src="/image/logo.png" alt="" className={styles.logo} />
        <img src="/image/logo2.png" alt="" className={`${styles.logo} ${styles.logo2}`} />
      </div>
      <ul>
        {
          list.map((el, index) => <>
            {
              el.chilren ?
                <li
                  onMouseMove={() => {
                    if (!mouseMoveChilrenShow) {
                      setMouseMoveChilrenShow(true)
                    }
                  }
                  }
                  onMouseOut={() => {
                    setMouseMoveChilrenShow(false)
                  }}
                >
                  <span className={el.chilrenRoutes.find(val => val === pathname) ? styles.hit : ''}>{el.label}</span>
                  <div className={styles.mork} id={'demo'}>
                    <ProductServices {...props}></ProductServices>
                  </div>
                </li> :
                <li
                  onClick={() => props.history.push(el.href)}
                ><span className={pathname === el.href ? styles.hit : ''}>{el.label}</span></li>
            }
          </>)
        }
        {/*<li onClick={() => props.history.push('/')}>首页</li>*/}
        {/*<li>*/}
        {/*  <span>产品服务</span>*/}
        {/*  <div className={styles.mork} id={'demo'}>*/}
        {/*    <ProductServices {...props}></ProductServices>*/}
        {/*  </div>*/}
        {/*</li>*/}
        {/*<li onClick={() => props.history.push('/riskControl')}>解决方案</li>*/}
        {/*<li onClick={() => props.history.push('/cooperationChannels')}>合作渠道</li>*/}
        {/*<li onClick={() => props.history.push('/aboutUs')}>关于我们</li>*/}
      </ul>
      <div className={styles.phone}>
        {/* <span className={styles.login} onClick={() => props.history.push('/login')}>登录</span> */}
        联系我们：400-019-1551
      </div>
      <div className={styles.linkbtn}>
        <a
          href="http://www.szjf.k8s:30081/#/login"
        >
          免费注册
        </a>
      </div>
    </div>
  );
}

export default Header;
