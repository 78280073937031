import styles from './index.module.less'
import {useEffect, useState} from "react";

function Header(props: any) {
  // const [ scrollTop, setScrollTop ] = useState(0)
  let scrollTop = 100
  const [ show, setShow ] = useState(false)
  const list = [
    { label: '联系客服', icon: '/image/BroadsideModuleT1.svg',
      chilren: <div className={styles.QRBox}>
        <img src="/image/kyCode.jpg" alt=""/>
      </div>,
      onClick: () => {}
    },
    { label: '关注我们', icon: '/image/BroadsideModuleT2.svg',
      chilren: <div className={styles.QRBox}>
        <img src="/image/gzhCode.jpg" alt=""/>
      </div>,
      onClick: () => {}
    },
    { label: '返回顶部', icon: '/image/BroadsideModuleT3.svg', onClick: () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  ]

  useEffect(() => {
    // const scrollTopPx = document.documentElement.scrollTop || document.body.scrollTop;
    // setScrollTop(scrollTopPx)
    window.addEventListener('scroll', () => {
      const scrollTopPx = document.documentElement.scrollTop || document.body.scrollTop;
      console.log(scrollTopPx, scrollTop)
      if (scrollTopPx > scrollTop) {
        setShow(true)
      } else {
        setShow(false)
      }
      // scrollTop = scrollTopPx
    }, true)
  }, [])

  return (
    <div className={`${styles.BroadsideModule} ${show ? styles.show : ''}`}>
      <ul>
        {
          list.map((el, index) => <>
            <li key={index} onClick={() => el.onClick()}><img src={el.icon} alt="" /> {el.label}{ el.chilren }</li>
            <li className={styles.border}></li>
          </>)
        }
      </ul>
    </div>
  );
}

export default Header;
