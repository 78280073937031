import React, {useEffect} from 'react';
import Header from '@/components/CommonHeader/index'
import IntroduceOne from "@/components/CommonIntroduceOne/index"
import IntroduceTwo from '@/components/CommonIntroduceTwo/index'
import IntroduceThree from '@/components/CommonIntroduceThree/index'
import CommonFooterList from '../../../../components/CommonFooterList/index'
import styles from './index.module.less'
import CommonIntroduceFour from "@/components/CommonIntroduceFour";

function App(props: any) {
    useEffect(() => {
      console.log('query', props)
    }, []);

    return (
      <div className={styles.home}>
        <div className={styles.header}>
          <Header
            title='金服助贷系统'
            subTitle={'通过科技手段为中小微企业提供便捷融资支持的平台。'}
            bgImage={'/image/LoanAssistanceBg.png'}
            bgMain={'/image/LoanAssistanceMain.png'}
            style={{
              height: '560px',
            }}
          ></Header>
        </div>
        <div className={`wrapItem`}>
          <CommonIntroduceFour
            subTitle='Standardization of employee processes'
            title='业务流程、风控线上化'
            text='助贷系统是一种基于互联网和金融科技的创新平台，通过整合多方资源和数据分析，为中小企业提供快速便捷的融资支持。助贷系统通过自动化的流程和智能化的风控模型，能够准确评估个人或企业信用和还款能力，为企业提供个性化的贷款方案。同时，助贷系统还提供便捷的申请和审批流程，大大缩短了融资周期，帮助企业更好地满足资金需求，推动经济发展。'
            image={'/image/LoanAssistanceT1Img.png'}
            btnType={'btn'}
          ></CommonIntroduceFour>
        </div>
        <div className={`wrapItem`}>
          <IntroduceTwo
            title='行业现状'
            subTitle='Industry status'
            list={[
              {
                icon: '/image/IndustrystatusItemT1.svg',
                intro: '传统金融机构对于小微企业的融资要求较高，往往需要抵押物或者良好的信用记录'
              },
              {
                icon: '/image/IndustrystatusItemT2.svg',
                intro: '传统贷款申请流程耗时且繁琐，需要大量的纸质文件和资料，对于企业来说是一个不小的负担'
              },
              {
                icon: '/image/IndustrystatusItemT3.svg',
                intro: '传统的风险评估模型主要依赖于历史数据和抵押物，难以准确评估其信用和还款能力'
              }
            ]}
          ></IntroduceTwo>
        </div>
        <div className={`wrapItem`}>
          <IntroduceThree
            title='系统优势 ｜ System Advantages'
            list={[
              { label: '快速融资', intro: '助贷系统可以提供快速的贷款申请和审批流程，大大缩短了企业的融资周期，并给出适合的贷款方案。' },
              { label: '便捷操作', intro: '助贷系统提供了在线填写申请、上传资料、查询贷款进度等功能，企业可以随时随地进行操作，无需繁琐的纸质文件和复杂的人工办理流程，提高了办理融资的便利性和效率。' },
              { label: '数据管理和分析', intro: '助贷系统能够根据企业的实际需求和信用状况，提供个性化的贷款方案，例如利率、还款期限、还款方式等方面灵活可调。' }
            ]}
            image='/image/IntroduceThreeBgtBg.png'
          ></IntroduceThree>
        </div>
        <div className={`wrapItem`}>
          <CommonFooterList {...props}></CommonFooterList>
        </div>
      </div>
    );
}

export default App;
