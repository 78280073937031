import React, {useEffect, useState} from 'react';
import styles from './index.module.less'
import { Col, Row } from 'antd';

function App(props: any) {
  const [ list ] = useState([
    { label: '数字金服智能风控' },
    { label: '个人信用及风险大数据' },
    { label: '企业信用及风险咨询' },
    { label: '数字化融资匹配' },
    { label: '企业税务风险检测' }
  ])

  return (
    <div className={styles.IntroduceTwo}>
      <div className={styles.display}></div>
      <h5>多维数字化分析助力金融决策能力</h5>
      <h6>通过大数据分析SaaS系统作为桥梁，迅速实现客户数据源，产品服务的闭环</h6>
      <div className={styles.wrap}>
        <div className={styles.left} style={{background: 'url(/image/IntroduceTwoBg.png) 100% no-repeat'}}>
          <div className={styles.box}>
            <h5>解决方案</h5>
            <p>利用智能生成分析结果，自动匹配产品服务，助力企业快速筛选最佳解决方案</p>
            <div
              className={`flex-cc ${styles.btn}`}
              onClick={() => props.history.push('/riskControl')}
            >更多解决方案</div>
          </div>
        </div>
        <div className={styles.right}>
          {
            list.map((el, index) => <div key={index} className={`flex-c ${styles.item}`}>
              <p>{el.label}</p>
            </div>)
          }
        </div>
      </div>
    </div>
  );
}

export default App;
