import React, {useEffect, useRef, useState} from 'react';
import { Carousel } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import styles from './index.module.less'

function App(props: any) {
  const { list } = props
  const CarouselRef: any = useRef()
  const [CarouselShow, setCarouselShow] = useState(true)

  useEffect(() => {
    console.log('propspropsprops', props.query.index)
    setCarouselShow(false)
    setTimeout(() => {
      setCarouselShow(true)
    })
  }, [props.query.index])

  useEffect(() => {
    if (props.query.index) {
      const rollEl = document.getElementById(`manageApiArrowRightOutlined`)
      if (rollEl) {
        document.body.scrollTop = rollEl.offsetTop - 140;
        document.documentElement.scrollTop = rollEl.offsetTop - 140;
      }
    }
  }, [props.query.time])

  return (
    <div className={styles.CommonCarousel}>
      <div className={styles.title}>
        <div className={`flex-cc ${styles.iconBtn} ${styles.left}`} onClick={() => CarouselRef.current.prev()}>
          <ArrowLeftOutlined />
        </div>
        <div className={styles.titleTxt} id={`manageApiArrowRightOutlined`}>数据介绍</div>
        <div className={`flex-cc ${styles.iconBtn} ${styles.right}`} onClick={() => CarouselRef.current.next()}>
          <ArrowRightOutlined />
        </div>
      </div>

      {
        CarouselShow && <Carousel initialSlide={props.query.index * 1 || 0} ref={CarouselRef}>
          {
            list.map((el: any, index: number) => <div key={index} className={styles.itemBox}>
              <ul className={`flex-c`}>
                {
                  el.map((val: any,j: number) => <li key={j}>
                    <div className={`flex-c ${styles.top}`}>
                      <img src={val.icon} alt=""/>
                      <span>{val.title}</span>
                    </div>
                    <div className={styles.text}>{val.text}</div>
                  </li>)
                }
              </ul>
            </div>)
          }
          </Carousel>
      }
    </div>
  );
}

export default App;
