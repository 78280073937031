import React, {useRef} from 'react';
import styles from './index.module.less'
import Header from "@/components/CommonHeader";
import ItemOne from './components/ItemOne/index'
import Reason from './components/Reason/index'
import DataInput from './components/DataInput/index'
import CommonApply from "@/components/CommonApply";

function App(props: any) {
  const CommonApplyRef = useRef<any>(null)

    return (
      <div className={styles.cooperationChannels}>
        <CommonApply ref={CommonApplyRef}></CommonApply>
        <Header
          title='携手合作，共同开创辉煌的明天'
          subTitle={'全方位赋能，助力伙伴持续高速成长：提供优质产品、专业培训、高效运营以及丰富资源，全方位助力合作伙伴实现高速成长，创造更广阔的发展空间'}
          bgImage={'/image/cooperationChannelsHeaderBg.png'}
          style={{
            height: '492px'
          }}
          RightView={<div className={`flex-cc ${styles.btn}`} onClick={() => CommonApplyRef.current.open()}>我要加盟</div>}
          btn={false}
        ></Header>
        <ItemOne></ItemOne>
        <Reason></Reason>
        <DataInput></DataInput>
      </div>
    );
}

export default App;
