/*
 * @Author: rogi
 * @Date: 2022-10-25 16:13:03
 * @LastEditTime: 2022-12-08 17:32:40
 * @LastEditors: rogi
 * @Description: 描述
 */
export function browserRedirect(): 'PE' | "PC" {
  let sUserAgent: string = navigator.userAgent.toLowerCase();
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
    return "PE"
  }
  return "PC"
}

export const flatArray = (flatList: any[], key: string) => {
  const newList: any[] = [];
  function isArr(list: any[]) {
    list.forEach(el => {
      newList.push(el);
      if (key && el[key] && el[key].length) {
        isArr(el[key])
      } else if (!key && Object.prototype.toString.call(el) === '[object Array]') {
        isArr(el)
      }
    })
  };
  isArr(flatList);
  return newList
};

export const fileToBase64 = (file: any) => {
  return new Promise((res) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function () {
      res(this.result)
    }
  })
};

export const copyText = (value: string): void => {
  // 创建元素用于复制
  var aux = document.createElement("input");
  aux.setAttribute("value", value);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand("copy");
  document.body.removeChild(aux);
  alert('复制成功')
};



export const getUrlParams = (url: string): {} => {
  let urlStr = url.split('?')[1] || '';
  let obj: any = {};
  let paramsArr = urlStr.split('&');
  for(let i = 0,len = paramsArr.length;i < len;i++){
    let arr = paramsArr[i].split('=');
    obj[arr[0]] = arr[1];
  }
  return obj
}
