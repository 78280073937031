import React, {useEffect, useState} from 'react';
import styles from './index.module.less'
import { Col, Row } from 'antd';

function App(props: any) {
  const { title, subTitle, list } = props

  return (
    <div className={styles.IntroduceOne}>
      <h5>{title}</h5>
      <h6>{subTitle}</h6>
      <div className={styles.IntroduceOneBox}>
        <img src="/image/IndustrystatusT1.png" alt="" className={styles.IndustrystatusLogo}/>
        <ul>
          {
            list.map((el: any, index: number) => <li>
              <img src={el.icon} alt=""/>
              <p className={styles.index}>{'0' + (index + 1)}</p>
              <p className={styles.intro}>{el.intro}</p>
            </li>)
          }
        </ul>
      </div>
    </div>
  );
}

export default App;
