import React, { useState } from 'react';
import OneItem from '../OneItem/index'
import OneItemTwo from '../OneItemTwo/index'
import styles from './index.module.less'

function App(props: any) {
  const { title, list, image, imageStyle } = props

  return (
    <div className={styles.IntroduceOne}>
      <OneItem
        title='信用及风险资讯'
        subTitle='对个人/企业进行涉诉查询，识别风险群体'
        list={[
          { label: '大数据、大格局、大服务', intro: '我们按照“大数据、大格局、大服务”的指示要求，致力于利用涉诉数据更好地服务经济社会建设。' },
          { label: '多维度数据开放', intro: '我们以司法大数据资源为基础，构建了个人/企业涉诉信息画像，为国家机关、金融机构等提供多维度数据开放服务。' }
        ]}
        tip={"构建画像 / 提供数据 /  大数据、大格局、大服务"}
        image='/image/TabT1T3.png'
      ></OneItem>
      <OneItemTwo
        title='方案亮点'
        subTitle='Highlights of the plan'
        two={true}
        list={[
          {
            icon: '/image/TabT3ItemT1.png',
            title: '高效快速',
            text: '查询诉讼信息的服务能够在短时间内提供准确的结果，帮助用户迅速获取所需信息。'
          },
          {
            icon: '/image/TabT3ItemT2.png',
            title: '全面覆盖',
            text: '系统具备广泛的数据来源和覆盖范围，能够查询全国各地的诉讼信息，提供更全面的内容。'
          },
          {
            icon: '/image/TabT3ItemT3.png',
            title: '多维度检索',
            text: '系统支持多种查询条件，如案件类型、当事人信息、法院名称等，用户可以根据自己的需求进行灵活的检索。'
          },
          {
            icon: '/image/TabT3ItemT4.png',
            title: '数据可视化',
            text: '查询到的诉讼信息可以通过可视化图表或统计报告的形式进行展示，帮助用户更直观地理解和分析数据。'
          }
        ]}
      ></OneItemTwo>
    </div>
  );
}

export default App;
